import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { AlertBox, Colors } from "swing-components";

import {
  AlertBoxError,
  ButtonScoreWrapper,
  ContentSingleColumn,
  ModalContentPhoneNumberEdit,
  RadioInput,
  Toggle,
  useAuth,
  useModal,
} from "~components";
import { GET, msg, NotificationsPayload, ProfileData, PUT } from "~utils";
import { ScorePage } from "../../ScoreTemplates";
import styles from "./Notifications.module.css";

enum NotificationType {
  SMS = "sms",
  PUSH = "push",
}

type Notification = {
  type: NotificationType;
  label: string;
  subText?: JSX.Element | string;
  isDisabled: boolean;
};

type NotificationsViewProps = {
  phoneNumber?: string;
  isLoading?: boolean;
  isPhoneNumberVerified?: boolean;
  // Note: The type on /api/sub/me is sms?: boolean while on /api/sub/notifications it's sms: boolean.
  notificationSettings?: { sms?: boolean; push?: boolean };
  isAlias?: boolean;
  refreshUser: () => void;
};

export function Notifications() {
  const {
    userInfo: { isPhoneNumberVerified, isAlias, notificationsSettings },
    refreshUser,
  } = useAuth();

  const { data: userData, isLoading } = useQuery<{ data: ProfileData }>({
    queryKey: ["fetchProfile"],
    queryFn: () => GET("/api/sub/profile"),
    enabled: !isPhoneNumberVerified,
  });

  return (
    <NotificationsView
      isLoading={isLoading}
      phoneNumber={userData?.data?.phoneNumber}
      isPhoneNumberVerified={isPhoneNumberVerified}
      notificationSettings={notificationsSettings}
      isAlias={isAlias}
      refreshUser={refreshUser}
    />
  );
}

export function NotificationsView(props: NotificationsViewProps) {
  const {
    isLoading,
    phoneNumber,
    isPhoneNumberVerified,
    notificationSettings,
    isAlias,
    refreshUser,
  } = props;

  const notificationTypes: Notification[] = [
    {
      type: NotificationType.SMS,
      label: msg("MORE_NOTIFICATIONS_TYPE_SMS"),
      subText: isPhoneNumberVerified ? undefined : (
        <>
          <span
            style={{ textDecoration: "underline", color: Colors.blue500, cursor: "pointer" }}
            onClick={() => openPhoneNumberEditModal()}
          >
            {msg("MORE_NOTIFICATIONS_TYPE_SMS_SUBTEXT_START")}
          </span>{" "}
          <span style={{ color: Colors.black }}>
            {msg("MORE_NOTIFICATIONS_TYPE_SMS_SUBTEXT_END")}
          </span>
        </>
      ),
      isDisabled: !isPhoneNumberVerified,
    },
    {
      type: NotificationType.PUSH,
      label: msg("MORE_NOTIFICATIONS_TYPE_PUSH"),
      subText: msg("MORE_NOTIFICATIONS_TYPE_PUSH_SUBTEXT"),
      // TODO: update when push notifications feature is available.
      isDisabled: true,
    },
  ];

  // If push is enabled currently, treat it as no notifications (toggle off).
  // This will be updated in the future.
  const isToggleInitiallyOn = !notificationSettings?.push && notificationSettings?.sms === true;
  // TODO: update notification type initial state once push is accessible.
  const initialNotificationType = notificationSettings?.sms ? NotificationType.SMS : undefined;

  /* Hooks */
  const [isToggleOn, setIsToggleOn] = useState(isToggleInitiallyOn);
  const [selectedNotificationType, setSelectedNotificationType] = useState<
    NotificationType | undefined
  >(initialNotificationType);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasPendingChanges, setHasPendingChanges] = useState(false);
  const queryClient = useQueryClient();

  const { mutate: updateNotificationsSettings, error: updateMutationError } = useMutation<
    { data: NotificationsPayload },
    AxiosError,
    NotificationsPayload
  >({
    mutationFn: (payload: NotificationsPayload) => {
      return PUT("/api/sub/notifications", payload);
    },
    onSuccess: (updatedSettingsData) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      queryClient.setQueryData(["userData"], (oldUserData: any) => {
        return {
          ...oldUserData,
          notificationsSettings: updatedSettingsData.data.notificationsSettings,
        };
      });
      // Using refreshUser to trigger a refetch of user data to
      // keep the `userInfo` in AuthProvider's context updated.
      refreshUser();
    },
  });

  useEffect(() => {
    // Reset pending changes when cache updates - the save button will be disabled to reflect their changes are the current
    setHasPendingChanges(false);
  }, [notificationSettings]);

  /* Modal */
  const { openModal, closeModal } = useModal({
    component: (
      <>
        {isModalOpen && (
          <ModalContentPhoneNumberEdit
            isPhoneNumberVerified={isPhoneNumberVerified}
            onDismiss={() => {
              setIsModalOpen(false);
              closeModal();
            }}
            phoneNumber={phoneNumber}
          />
        )}
      </>
    ),
    modalOptions: { cssClass: "modal-sub-profile-edit" },
  });

  const openPhoneNumberEditModal = () => {
    setIsModalOpen(true);
    openModal();
  };

  const handleToggleChange = (value: boolean) => {
    setIsToggleOn(value);
    setHasPendingChanges(true);
  };

  const handleNotificationTypeChange = (type: NotificationType) => {
    setSelectedNotificationType(type);
    setHasPendingChanges(true);
  };

  const handleSave = () => {
    let payload: NotificationsPayload;
    if (isToggleOn && selectedNotificationType) {
      payload = {
        notificationsSettings: {
          [selectedNotificationType]: true,
        },
      };
    } else {
      // Send empty payload to represent toggle off.
      // The BE will handle setting both types to false.
      payload = { notificationsSettings: {} };
    }
    updateNotificationsSettings(payload);
  };

  // Save is enabled only when there are changes.
  // If the toggle is on, a notification type must be selected to enable save.
  const isSaveEnabled =
    hasPendingChanges && (isToggleOn ? selectedNotificationType !== undefined : true);

  return (
    <ScorePage title={msg("MORE_NOTIFICATIONS")} isLoading={isLoading} hasBack>
      <ContentSingleColumn>
        <div className={styles.wrapper}>
          {updateMutationError && <AlertBoxError />}
          {/* If aliased, show a warning to prevent super-admins from altering push notifications */}
          {isAlias && <SuperAdminNotificationsWarning />}
          <div className={styles.toggleContainer}>
            <Toggle
              label={msg("MORE_NOTIFICATIONS_TOGGLE_LABEL")}
              isSelected={isToggleOn}
              onToggleChange={handleToggleChange}
            />
          </div>
          {isToggleOn && (
            <>
              <p style={{ fontWeight: 600 }}>{msg("MORE_NOTIFICATIONS_TYPE_HEADER")}</p>
              <div className={styles.notificationsWrapper}>
                {notificationTypes.map((type, idx) => (
                  <div
                    key={`${type.type}`}
                    className={styles.notificationItem}
                    style={{
                      borderBottom:
                        idx < notificationTypes.length - 1
                          ? `1px solid ${Colors.slate200}`
                          : "none",
                    }}
                  >
                    <RadioInput
                      value={type.type}
                      label={type.label}
                      isDisabled={type.isDisabled}
                      isSelected={selectedNotificationType === type.type}
                      onChange={(value) => handleNotificationTypeChange(value as NotificationType)}
                    />
                    {type.subText && (
                      <p
                        style={{
                          color: type.isDisabled ? Colors.slate300 : Colors.black,
                          fontSize: 12,
                          // Left margin of 36px to account for the radio button width and the gap (20px + 16px)
                          margin: "0 0 0 36px",
                          textAlign: "left",
                        }}
                      >
                        {type.subText}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </>
          )}
          <ButtonScoreWrapper
            buttonPrimary={{
              label: msg("LABEL_SAVE"),
              onClick: handleSave,
              disabled: !isSaveEnabled,
              expand: "block",
            }}
          />
        </div>
      </ContentSingleColumn>
    </ScorePage>
  );
}

function SuperAdminNotificationsWarning() {
  return (
    <div style={{ marginBottom: 24 }}>
      <AlertBox color="warning" title={msg("MORE_NOTIFICATIONS_SUPER_ADMIN_ALERT_TITLE")} showIcon>
        {msg("MORE_NOTIFICATIONS_SUPER_ADMIN_ALERT_MSG")}
      </AlertBox>
    </div>
  );
}
