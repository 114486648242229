import { ScorePage } from "src/pages/authenticated/ScoreTemplates";

import { PageProps } from "~components";

type SkillBuilderPageProps = Pick<PageProps, "children" | "isLoading">;

/**
 * Wrapper for the Skill Builder.
 *
 */
export function SkillBuilderPage(props: SkillBuilderPageProps) {
  const { isLoading, children } = props;

  /***** Render *****/
  return (
    <ScorePage title="" isLoading={isLoading} hasBack>
      {children}
    </ScorePage>
  );
}
