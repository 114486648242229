import { FeedbackQuestion } from "~skillBuilder/components";
import {
  computeTaskState,
  Task,
  TaskComputed,
  UnsyncedSkillBuilderAttribute,
} from "~skillBuilder/utils";
import type { Require, SkillBuilderAttribute } from "~utils";

/********** Types **********/
export type TaskPageReducerState = {
  /***** Initial States *****/
  /** Represents the state needed to compute which Step to render */
  stepIndex?: {
    /**
     * Set this value initially if you want to attempt to render this Step on
     * mount. This is used when refreshing the page, and having the URL set
     * this value so that the user stays on the Step that they were on.
     *
     * NOTE: This value will be set to `undefined` once it's used as the
     * presence of a value here is a trigger to render a specific Step vs the
     * next logical step (e.g. the next step directly after clicking the
     * "Next" button).
     */
    initial?: number;
    /**
     * Stores the current Step index and is used by the TaskPageBase component
     * to determine which Step to render.
     */
    current?: number;
  };
  /**
   * Represents the skill builder attributes which come from the API. We call these
   * "synced skill builder attributes" since they are known to the API.
   *
   * These values and the `task` field are used to compute the `taskComputed` value.
   */
  syncedSkillBuilderAttributes?: Array<SkillBuilderAttribute>;
  /**
   * The static Task definition which is used to compute the `taskComputed` field
   */
  task?: Task;
  /***** Runtime / Working State *****/
  /**
   * Represents the computed Task which is used by the TaskPageBase and it's
   * children component for rendering.
   */
  taskComputed?: TaskComputed;
  /**
   * Represents local changes to Question. We call these "unsynced skill builder attributes"
   * since they are in the same shape as the synced skill builder attributes although
   * the `value` may differ
   */
  unsyncedSkillBuilderAttributes?: Array<UnsyncedSkillBuilderAttribute>;
  /***** Flags *****/
  /**
   * Flag representing the loading state which happens:
   * - When waiting for the `taskComputed` field to be computed from the `task` and `syncedSkillBuilderAttributes` fields
   * - When submitting an answer, or change, to the API and waiting for the updated `syncedSkillBuilderAttributes`
   *
   * @default false
   */
  isLoading?: boolean;
  /**
   * Flag representing when we can no longer change a Question's answer which happens:
   * - When we have submitted an answer and waiting for the updated `syncedSkillBuilderAttributes`
   * - When the Task is `COMPLETE` since we can no longer change answers
   *
   * @default false
   */
  isReadOnly?: boolean;
  /***** Handlers *****/
  /**
   * Callback triggered when there are changes that need to be synced with the API.
   * We are only calling this handler when there are unsynced changes.
   */
  onSubmit: (state: Require<TaskPageReducerState, "unsyncedSkillBuilderAttributes">) => void;
  /**
   * Triggered when the Task should be closed (e.g. clicking "Close" button on a Step).
   */
  onClose: () => void;
  /**
   * Triggered when there is step change.
   */
  onStepChange: (stepIndex: number) => void;
  /**
   * Triggered when on the last step of a quiz task.
   */
  onQuizLastStep?: (isFeedbackComplete: boolean) => void;
};

type TaskPageReducerAction =
  /**
   * Should be called when receiving a new Task definition.
   */
  | { type: "SET_TASK"; payload: { task: Task } }
  /**
   * Should be called when receiving a new list of skill builder attributes.
   */
  | {
      type: "SET_SKILL_BUILDER_ATTRIBUTES";
      payload: {
        skillBuilderAttributes: Array<SkillBuilderAttribute>;
        quizLastStep?: number;
      };
    }
  /**
   * Should be called when there are any changes to a Question answer.
   */
  | {
      type: "UPDATE_ANSWER";
      payload: {
        skillBuilderAttribute: UnsyncedSkillBuilderAttribute;
      };
    }
  /**
   * Should be called when the "Next" button is clicked.
   */
  | { type: "NEXT_BUTTON"; payload?: { feedback?: FeedbackQuestion[] } }
  /**
   * Should be called when the "Previous" button is clicked.
   */
  | { type: "PREV_BUTTON"; payload?: { isShowPreviousButton?: boolean } };

export function TaskPageReducer(
  state: TaskPageReducerState,
  action: TaskPageReducerAction,
): TaskPageReducerState {
  const { type, payload } = action;

  switch (type) {
    case "SET_TASK": {
      if (state.syncedSkillBuilderAttributes === undefined) {
        // Return only the `task` since we cannot compute the `taskComputed`
        return { ...state, task: payload.task };
      }

      // Compute the Task
      const taskComputed = computeTaskState({
        task: payload.task,
        syncedSkillBuilderAttributes: state.syncedSkillBuilderAttributes,
      });

      // Determine the Step to render and trigger the `onStepChange` callback
      const newStepIndex = {
        current: getNextStepIndex({ initialStepIndex: state.stepIndex?.initial, taskComputed }),
      };

      state.onStepChange(newStepIndex.current);

      return {
        ...state,
        isLoading: false, // Remove the loading state as `taskComputed` is defined
        isReadOnly: taskComputed.status === "COMPLETED",
        stepIndex: newStepIndex,
        task: payload.task,
        taskComputed,
      };
    }
    case "SET_SKILL_BUILDER_ATTRIBUTES": {
      if (!state.task) {
        // Return just the `syncedSkillBuilderAttributes` since we cannot compute the `taskComputed`
        return { ...state, syncedSkillBuilderAttributes: payload.skillBuilderAttributes };
      }

      // Re-compute the Task state using the latest skill builder attributes, ignoring any
      // local changes since any local changes would have been submitted and cleared by now
      const taskComputed = computeTaskState({
        task: state.task,
        syncedSkillBuilderAttributes: payload.skillBuilderAttributes,
      });

      // Determine the Step to render and trigger the `onStepChange` callback
      const newStepIndex = {
        current: getNextStepIndex({
          // when on quiz last step and modal is opened then keep the user on this last quiz step
          initialStepIndex: payload.quizLastStep ? payload.quizLastStep : state.stepIndex?.initial,
          taskComputed,
        }),
      };

      state.onStepChange(newStepIndex.current);

      return {
        ...state,
        isLoading: false, // Remove the loading state as `taskComputed` is defined
        isReadOnly: taskComputed.status === "COMPLETED",
        stepIndex: newStepIndex,
        // Clear the unsyncedSkillBuilderAttributes since this happens after submitting the answers
        unsyncedSkillBuilderAttributes: [],
        // Update the syncedSkillBuilderAttributes with the payload
        syncedSkillBuilderAttributes: payload.skillBuilderAttributes,
        taskComputed,
      };
    }
    case "UPDATE_ANSWER": {
      // Bail when missing the required data to update the answer
      if (state.task === undefined || state.syncedSkillBuilderAttributes === undefined) {
        return state;
      }

      const newSkillBuilderAttribute = payload.skillBuilderAttribute;
      const { syncedSkillBuilderAttributes, unsyncedSkillBuilderAttributes = [] } = state;

      let newUsba = updateUnsyncedSkillBuilderAttributes({
        unsyncedSkillBuilderAttributes,
        newSkillBuilderAttribute,
      });
      const newSsba = [...syncedSkillBuilderAttributes];

      // Remove any unsynced skill builder attributes which have reverted to the original
      // value, which is the value of the synced skill builder attributes
      newUsba = newUsba.reduce((acc, usba) => {
        const matchingSsbaIndex = newSsba.findIndex((ssba) => ssba.attribute === usba.attribute);

        // When there is a match
        if (matchingSsbaIndex !== -1) {
          // And and the `value` is the same
          if (newSsba[matchingSsbaIndex].value === usba.value) {
            // Then ignore the unsynced skill builder attribute so
            // that we don't submit the same answer again
            return acc;
          }

          // At this point, we know that the unsynced skill builder attribute
          // has a matching synced skill builder attribute and that the local
          // change (usba) `value` should be taken as higher priority.

          // Get the matching synced skill builder attribute and remove that
          // element from newSsba array to remove duplicates.
          newSsba.splice(matchingSsbaIndex, 1)[0];

          // Then pass along fields
          return [
            ...acc,
            {
              ...usba,
            } satisfies UnsyncedSkillBuilderAttribute,
          ];
        }

        // When the local change has no matching synced
        // skill builder attribute then keep the change.
        return [...acc, usba];
      }, [] as Array<UnsyncedSkillBuilderAttribute>);

      // Compute the new reality using the new skill builder attributes
      const taskComputed = computeTaskState({
        task: state.task,
        syncedSkillBuilderAttributes: newSsba,
        unsyncedSkillBuilderAttributes: newUsba,
      });

      return {
        ...state,
        // Update the unsyncedSkillBuilderAttributes with the new values
        // so that it remains filtered of duplicates
        unsyncedSkillBuilderAttributes: newUsba,

        // NOTE: We are not updating the syncedSkillBuilderAttributes since we want to
        // keep the original values until the API has confirmed the changes.
        // The code above is removing duplicates.

        // Re-compute the `taskComputed` state
        taskComputed,
      };
    }
    case "NEXT_BUTTON": {
      // Bail when missing required data for this case
      if (state.stepIndex?.current === undefined || state.taskComputed === undefined) {
        return state;
      }

      // If the Task is not complete and we have unsynced changes
      if (state.unsyncedSkillBuilderAttributes?.length ?? 0 > 0) {
        // Then submit the answers to the API
        state.onSubmit(state as Require<TaskPageReducerState, "unsyncedSkillBuilderAttributes">);

        return {
          ...state,
          // And add the loading flag
          isLoading: true,
          // Disable changing answers while we are submitting
          isReadOnly: true,
        };
      }

      // Move to the next step if it's not the last step
      if (state.stepIndex.current < state.taskComputed.steps.length - 1) {
        const newStepIndex = {
          ...state.stepIndex,
          current: state.stepIndex.current + 1,
        };

        state.onStepChange(newStepIndex.current);
        return { ...state, stepIndex: newStepIndex };
      }

      // If it's a quiz task AND the last step then
      // check whether feedback has been completed
      if (
        state.stepIndex.current === state.taskComputed.steps.length - 1 &&
        state.taskComputed.isQuiz
      ) {
        // If syncedSkillBuilderAttributes contains all feedback question attributes then deem as complete.
        // Note: Optional questions that have empty answers will send "valid" to the BE so will be in syncedSkillBuilderAttributes
        const feedbackAttributes = payload?.feedback?.map((f) => f.attribute);
        const savedAttributeNames = state.syncedSkillBuilderAttributes?.map(
          (sbas) => sbas.attribute,
        );
        const isFeedbackComplete =
          feedbackAttributes && savedAttributeNames
            ? feedbackAttributes.every((fa) => savedAttributeNames.includes(fa))
            : false;
        state.onQuizLastStep ? state.onQuizLastStep(isFeedbackComplete) : state.onClose();
        return state;
      }

      // Go back to the Tasks page when we are on the last step
      state.onClose();

      return state;
    }
    case "PREV_BUTTON": {
      // Bail when missing required data
      if (
        state.stepIndex?.current === undefined ||
        state.task == undefined ||
        state.syncedSkillBuilderAttributes === undefined
      ) {
        return state;
      }

      // Go to tasks page when on first step of task and has a previous button
      if (state.stepIndex.current === 0 && payload?.isShowPreviousButton) {
        state.onClose();
        return state;
      }

      // Bail when on the first step and step has no previous button
      if (state.stepIndex.current === 0 && !payload?.isShowPreviousButton) {
        return state;
      }

      const newStepIndex = {
        ...state.stepIndex,
        current: state.stepIndex.current - 1,
      };

      // Re-compute the Task without the local changes
      const newTaskComputed = computeTaskState({
        task: state.task,
        syncedSkillBuilderAttributes: state.syncedSkillBuilderAttributes,
      });

      state.onStepChange(newStepIndex.current);

      return {
        ...state,
        stepIndex: newStepIndex,
        // Clear local changes when going to the previous step
        unsyncedSkillBuilderAttributes: [],
        taskComputed: newTaskComputed,
      };
    }
  }
}

/**
 * Merge the new skill builder attribute into the unsynced skill builder
 * attributes removing any duplications.
 *
 * We need this function since we cannot simply append the new skill builder
 * attributes to the unsynced skill builder attributes since there may be
 * duplicate attribute fields
 */
function updateUnsyncedSkillBuilderAttributes(params: {
  newSkillBuilderAttribute: UnsyncedSkillBuilderAttribute;
  unsyncedSkillBuilderAttributes: Array<UnsyncedSkillBuilderAttribute>;
}): Array<UnsyncedSkillBuilderAttribute | SkillBuilderAttribute> {
  const { newSkillBuilderAttribute, unsyncedSkillBuilderAttributes } = params;

  const isMatching = unsyncedSkillBuilderAttributes.find(
    (usba) => usba.attribute === newSkillBuilderAttribute.attribute,
  );

  if (!isMatching) return [...unsyncedSkillBuilderAttributes, newSkillBuilderAttribute];

  return unsyncedSkillBuilderAttributes.map((usba) => {
    if (usba.attribute === newSkillBuilderAttribute.attribute) return newSkillBuilderAttribute;
    return usba;
  });
}

/**
 * Returns the next Step index to render, and if given an `initialStepIndex`
 * this will be chosen only if it's valid (e.g. not an index which would be
 * skipping a Step).
 */
function getNextStepIndex(params: { initialStepIndex?: number; taskComputed: TaskComputed }) {
  const { initialStepIndex, taskComputed } = params;

  // Determine the next Step index should the `initialStepIndex` not
  // be provided, which is whichever Step that is not `COMPLETE`.
  let nextStepIndex = taskComputed.steps.findIndex((step) => step.status !== "COMPLETED");

  // If all steps are complete, then set index to be the first Step
  if (nextStepIndex === -1) nextStepIndex = 0;

  // However, set index to be the initialStepIndex if provided and its not greater than the number of steps available
  if (initialStepIndex !== undefined && initialStepIndex <= taskComputed.steps.length - 1) {
    return initialStepIndex;
  }

  return nextStepIndex;
}
