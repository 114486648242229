import { ChangeEvent, CSSProperties, RefObject, useEffect, useRef } from "react";
import { Asterisk, Colors } from "swing-components";

import style from "./TextAreaInput.module.css";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  } as CSSProperties,
  label: {
    fontWeight: "600",
    color: Colors.black,
  } as CSSProperties,
  inputWrapper: ({ isDisabled }: Pick<TextAreaInputProps, "isDisabled">) =>
    ({
      borderBottom: `1px solid ${isDisabled ? "transparent" : Colors.slate200}`,
    }) as CSSProperties,
  nativeInput: ({ isDisabled }: Pick<TextAreaInputProps, "isDisabled">) =>
    ({
      padding: 0,
      border: 0,
      width: "100%",
      maxWidth: "100%",
      maxHeight: "100%",
      color: isDisabled ? Colors.slate400 : Colors.black,
      background: "transparent",
      outline: "none",
      appearance: "none",
      touchAction: "manipulation",
      resize: "none",
    }) as CSSProperties,
};

type TextAreaInputProps = {
  label?: string;
  value?: string;
  placeholder?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  onChange: (value: string) => void;
};

export function TextAreaInput(props: TextAreaInputProps) {
  const { value, label, placeholder, onChange, isDisabled, isRequired = false } = props;

  /***** Constants *****/
  // Defaulting to am empty string to remove `controlled` input error.
  const _value = value || "";

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useAutoSizeTextArea(textAreaRef, _value);

  /***** Render *****/
  return (
    <label style={styles.container}>
      {label && (
        <div style={styles.label}>
          {label}
          {/* TODO match types for asterisk */}
          {isRequired && <Asterisk />}
        </div>
      )}
      <div style={styles.inputWrapper({ isDisabled })}>
        <textarea
          className={style.placeHolderText}
          style={styles.nativeInput({ isDisabled })}
          disabled={isDisabled}
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          spellCheck={false}
          aria-label={label}
          placeholder={placeholder}
          value={_value}
          ref={textAreaRef}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value)}
        />
      </div>
    </label>
  );
}

// Updates the height of a <textarea> when the value changes.
const useAutoSizeTextArea = (textAreaRef: RefObject<HTMLTextAreaElement>, value: string) => {
  useEffect(() => {
    if (!textAreaRef.current) return;

    const textArea = textAreaRef.current;

    const adjustHeight = () => {
      if (!textArea) return;
      // Reset the height to get the correct scrollHeight
      textArea.style.height = "0px";
      textArea.style.height = `${textArea.scrollHeight}px`;
    };

    const observerCallback: ResizeObserverCallback = (entries) => {
      // Using requestAnimationFrame to prevent infinite resize loops
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || entries.length === 0) return;
        // Adjust the height on value change
        adjustHeight();
      });
    };

    const resizeObserver = new ResizeObserver(observerCallback);
    resizeObserver.observe(textArea);

    // Initial height adjustment
    adjustHeight();

    return () => {
      resizeObserver.disconnect();
    };
  }, [textAreaRef, value]);
};
