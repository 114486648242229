import { jsx as e, jsxs as o, Fragment as g } from "react/jsx-runtime";
import { I as w, g as G } from "../../../Icon-Dm1kHhoN.js";
import { IonBackdrop as O, IonButton as _, IonText as d } from "@ionic/react";
import { useState as c } from "react";
import { Colors as A } from "../../foundations/Colors/Colors.js";
import { u as T, f as x, E as S } from "../../../AuthProvider-eYZfUA7K.js";
import i from "../../string-table.js";
import "../../../react-paginate-Lh5zQzpz.js";
import '../../../css/Login.css';const B = "_form_1gsys_1", R = "_divider_1gsys_5", C = "_emailInput_1gsys_27", k = "_loginButton_1gsys_31", D = "_buttonText_1gsys_39", M = "_googleIcon_1gsys_44", V = "_loadingContainer_1gsys_54", W = "_loader_1gsys_71", Y = "_curve_1gsys_1", s = {
  form: B,
  divider: R,
  emailInput: C,
  loginButton: k,
  buttonText: D,
  googleIcon: M,
  loadingContainer: V,
  loader: W,
  curve: Y,
  "swing-s": "_swing-s_1gsys_85"
};
function X(n) {
  const {
    loginWithPasswordPath: a,
    history: I,
    onLoginEmailSubmit: f
  } = n, {
    signIn: m,
    isAuthenticating: E
  } = T(), [r, h] = c(""), [N, l] = c(!1), [p, u] = c(!1), L = (t) => {
    h(t), j(t) ? u(!0) : u(!1);
  }, b = async () => {
    const t = await f(r).catch(() => l(!0));
    switch (t) {
      case "COGNITO":
        l(!1), I.push({
          pathname: a,
          // Explicitly not using `useLocation` as we want to live value vs
          // cached value.
          search: window.location.search,
          state: {
            email: r
          }
        });
        break;
      case "GOOGLE":
        m({
          provider: t.toLowerCase()
        }).catch(() => {
          l(!0);
        });
        break;
      default:
        l(!0);
        break;
    }
  };
  async function y(t) {
    t.preventDefault(), await b();
  }
  async function v(t) {
    t.preventDefault(), await m({
      provider: "google"
    });
  }
  return /* @__PURE__ */ e(x, {
    title: i("LOGIN_EMAIL_ENTRY_LOGIN_MESSAGE"),
    children: E ? /* @__PURE__ */ o(g, {
      children: [/* @__PURE__ */ e(O, {
        visible: !0
      }), /* @__PURE__ */ o("div", {
        className: s.loadingContainer,
        children: [/* @__PURE__ */ e("img", {
          alt: "",
          src: "assets/swing-s.svg",
          className: s["swing-s"]
        }), /* @__PURE__ */ e("img", {
          alt: i("LABEL_SWING_LOADING_ALT"),
          src: "assets/swing-curve.svg",
          className: s.loader
        })]
      })]
    }) : /* @__PURE__ */ o(g, {
      children: [/* @__PURE__ */ o("form", {
        className: s.form,
        onSubmit: async (t) => await y(t),
        children: [/* @__PURE__ */ e("div", {
          className: s.emailInput,
          children: /* @__PURE__ */ e(S, {
            email: r,
            onEmailInput: (t) => L(t),
            readOnly: !1,
            error: N
          })
        }), /* @__PURE__ */ o(_, {
          type: "submit",
          size: "default",
          expand: "block",
          className: s.loginButton,
          disabled: !p,
          children: [/* @__PURE__ */ e(d, {
            className: s.buttonText,
            children: i("LOGIN_EMAIL_ENTRY_LOGIN_LABEL_ENTER_PASSWORD")
          }), /* @__PURE__ */ e(w, {
            name: "Arrow Right",
            color: A.white200
          })]
        })]
      }), /* @__PURE__ */ e(P, {
        label: i("LOGIN_EMAIL_ENTRY_LABEL_OR")
      }), /* @__PURE__ */ e("form", {
        className: s.form,
        onSubmit: async (t) => await v(t),
        children: /* @__PURE__ */ o(_, {
          type: "submit",
          size: "default",
          fill: "outline",
          expand: "block",
          className: s.loginButton,
          children: [/* @__PURE__ */ e("img", {
            src: G,
            className: s.googleIcon,
            alt: "Login with Google"
          }), /* @__PURE__ */ e(d, {
            className: s.buttonText,
            children: i("LOGIN_EMAIL_ENTRY_LOGIN_LABEL_CONTINUE_WITH_GOOGLE")
          })]
        })
      })]
    })
  });
}
function P(n) {
  const {
    label: a
  } = n;
  return /* @__PURE__ */ e("div", {
    className: s.divider,
    children: a
  });
}
function j(n) {
  return new RegExp(/.+@.+\..{2,}/).test(n);
}
export {
  X as Login,
  j as isValidEmail
};
