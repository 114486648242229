import { jsx as n, jsxs as r } from "react/jsx-runtime";
import "@ionic/react";
import "../../../../../AuthProvider-eYZfUA7K.js";
import c from "../../../../string-table.js";
import "react";
import "../../../../../react-paginate-Lh5zQzpz.js";
import { Section as h } from "../Section/Section.js";
import '../../../../../css/WorkExperience.css';const O = "_experienceContentList_jgbh7_1", R = "_experienceContentListItem_jgbh7_7", L = "_experienceContentDate_jgbh7_11", N = "_noExperience_jgbh7_16", A = "_experienceContent_jgbh7_1", o = {
  experienceContentList: O,
  experienceContentListItem: R,
  experienceContentDate: L,
  noExperience: N,
  experienceContent: A
};
function g(e) {
  const {
    editable: i,
    experiences: s
  } = e;
  return /* @__PURE__ */ n(h, {
    onEditClick: i != null && i.isEditable ? i.onEditWorkExperience : void 0,
    title: c("SUB_PROFILE_RELEVANT_WORK_EXPERIENCE_HEADER"),
    children: /* @__PURE__ */ n("div", {
      children: s && s.length > 0 ? /* @__PURE__ */ n("ul", {
        className: o.experienceContentList,
        children: s.map((t) => {
          const a = new Date(t.startDate), l = new Date((t == null ? void 0 : t.endDate) || "");
          return /* @__PURE__ */ n("li", {
            className: o.experienceContentListItem,
            children: /* @__PURE__ */ r("div", {
              className: o.experienceContent,
              children: [/* @__PURE__ */ n("div", {
                className: o.experienceContentDate,
                children: /* @__PURE__ */ r("span", {
                  children: [`${a.getMonth() + 1}/${a.getFullYear()}`, `${t.endDate ? ` - ${l.getMonth() + 1}/${l.getFullYear()}` : " - Present"}`]
                })
              }), /* @__PURE__ */ r("div", {
                className: o.experienceContentInfo,
                children: [/* @__PURE__ */ r("div", {
                  children: [/* @__PURE__ */ n("span", {
                    style: {
                      fontWeight: "var(--swing-font-semibold)"
                    },
                    children: `${t.organization}`
                  }), /* @__PURE__ */ r("span", {
                    children: [", ", t.role]
                  })]
                }), /* @__PURE__ */ n("div", {
                  className: o.experienceContentRole,
                  style: {
                    fontStyle: "italic"
                  },
                  children: `${d[t.type]}, (${t.tags.map((E, m, C) => m !== 0 && m <= C.length - 1 ? ` ${_[E]}` : _[E])})`
                })]
              })]
            })
          }, t.id);
        })
      }) : /* @__PURE__ */ n("span", {
        className: o.noExperience,
        children: i != null && i.isEditable ? c("SUB_PROFILE_RELEVANT_WORK_EXPERIENCE_EDIT_EMPTY") : c("SUB_PROFILE_NO_INFORMATION")
      })
    })
  });
}
var _ = /* @__PURE__ */ ((e) => (e.EXP_TAG_K_5 = "K-5", e.EXP_TAG_ADULT = "Adult", e.EXP_TAG_PRE_K = "Pre-K", e.EXP_TAG_9_12 = "9-12", e.EXP_TAG_6_8 = "6-8", e))(_ || {}), d = /* @__PURE__ */ ((e) => (e.SUBSTITUTE_TEACHER = "Substitute Teacher", e.ADMINISTRATOR = "Administrator", e.CLASSROOM_LEAD = "Classroom Lead", e.SMALL_GROUP_INSTRUCTION = "Small Group Instruction", e.ONE_ON_ONE = "One on One", e.CLASSROOM_SUPPORT = "Classroom Support", e))(d || {});
export {
  _ as ExperienceTag,
  d as ExperienceType,
  g as WorkExperience
};
