import { History } from "history";
import { DateTime } from "luxon";
import { generatePath } from "react-router";

import { createRequestsUrl } from "~pages";
import { msg, OpeningsData, RequestData } from "~utils";

export function centsToDollars(valueInCents: number): string {
  return (valueInCents / 100).toLocaleString("en-US", { style: "currency", currency: "USD" });
}

export function payDisplay(amount: string, suffix = "", seperator = "/") {
  if (suffix == "") {
    return `${amount}${suffix}`;
  } else {
    return `${amount}${seperator}${suffix}`;
  }
}

export function isMultiDay(intervals: RequestData["intervals"]) {
  return intervals.length > 1;
}

export function requestTimeDisplay(data: OpeningsData) {
  return isMultiDay(data.intervals)
    ? `${data.intervals.length} ${msg("REQUEST_TIME_UNIT")}`
    : data.displayTime;
}

const GRACE_PERIOD_CUTOFF_IN_MINS = 30;
const PENALTY_PERIOD_CUTOFF_IN_HRS = 24;

export type CancelPenaltyType =
  | "NO_PENALTY"
  | "PENALTY_REQUEST_STARTED"
  | "PENALTY_REQUEST_NOT_STARTED";

export type CalcCancelPenaltyTypeProps = {
  reqStartTime: string | DateTime;
  reqFillTime: string | DateTime;
  reqCancelTime: string | DateTime;
};

// NOTE: Expects `props` keys to be one of two types:
// 1. a date string in ISO format set to UTC 0
// 2. a DateTime set to UTC
export function calcCancelPenaltyType(props: CalcCancelPenaltyTypeProps): CancelPenaltyType {
  let { reqStartTime, reqFillTime, reqCancelTime } = props;

  if (typeof reqStartTime === "string") {
    reqStartTime = DateTime.fromISO(reqStartTime, { zone: "utc" });
  }

  if (typeof reqFillTime === "string") {
    reqFillTime = DateTime.fromISO(reqFillTime, { zone: "utc" });
  }

  if (typeof reqCancelTime === "string") {
    reqCancelTime = DateTime.fromISO(reqCancelTime, { zone: "utc" });
  }

  const graceThreshold = reqFillTime.plus({
    minutes: GRACE_PERIOD_CUTOFF_IN_MINS,
  });
  const startThreshold = reqStartTime.minus({
    hours: PENALTY_PERIOD_CUTOFF_IN_HRS,
  });
  const isCancelTimeInsideGracePeriod = reqCancelTime <= graceThreshold;
  const isCancelTimeOutsidePenaltyPeriod = reqCancelTime <= startThreshold;
  const isCancelTimeAfterRequestStart = reqCancelTime > reqStartTime;

  if (isCancelTimeAfterRequestStart) {
    return "PENALTY_REQUEST_STARTED";
  }

  if (isCancelTimeInsideGracePeriod || isCancelTimeOutsidePenaltyPeriod) {
    return "NO_PENALTY";
  }

  return "PENALTY_REQUEST_NOT_STARTED";
}

type GenerateGoogleMapsLinkProps = Partial<{
  street: string;
  city: string;
  state: string;
  zip: string;
}>;

export function generateGoogleMapsLink(props: GenerateGoogleMapsLinkProps): string {
  const { street, city, state, zip } = props;

  return `http://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    `${street} ${city}, ${state}, ${zip}`,
  )}`;
}

// based on whether request is hourly
export function formatPay(isHourly: boolean, paymentInCents: number) {
  const dollars = centsToDollars(paymentInCents);
  if (isHourly) {
    return `${payDisplay(dollars, "hr")}`;
  }
  return `${dollars}`;
}

export function maybePluralize(str: string, count: number): string {
  if (count === 1) {
    return `${count} ${str}`;
  } else {
    return `${count} ${str}s`;
  }
}

// Header Navigation
export function generateBackNavigation(history: History) {
  const splitPathName = history.location.pathname.toString().split("/").filter(Boolean);
  splitPathName.pop();
  // to account for no school list page in sub app
  if (splitPathName.includes("school") || splitPathName.includes("skill-builder")) {
    splitPathName.pop();
  }
  return splitPathName.length > 0
    ? history.push(generatePath(`/${splitPathName.join("/")}`))
    : history.push(createRequestsUrl());
}

export const formatDisplayTimes = (times: string) => {
  return times.replace("AM", " am").replace("PM", " pm");
};

export function formatStringArray(items: string[]) {
  return items.join(", ");
}

// TODO: move this into "OpeningsCard" component once created
export function formatGradesAndSubjects(grades: string[], subjects: string[]) {
  // grades - selected
  let g = formatStringArray(grades);
  // subjects - selected
  let s = formatStringArray(subjects);

  // grades - not selected
  if (g.toLowerCase() === "") {
    g = msg("GRADE_NOT_PROVIDED");
  }

  // grades - unknown
  if (g.toLowerCase() === "unknown") {
    g = msg("GRADE_UNKNOWN");
  }

  // subjects - not selected
  if (s.toLowerCase() === "") {
    s = msg("SUBJECT_NOT_PROVIDED");
  }

  // subjects - unknown
  if (s.toLowerCase() === "unknown") {
    s = msg("SUBJECT_UNKNOWN");
  }

  return formatStringArray([g, s]);
}

/**
 * Returns an array of length `n` where each element is the result of calling
 * `fn` with the index of the element.
 *
 * @example
 * const [q1, q2, q3] = numberOf(3,
 *  (index, total) => newQuestion({ label: `Question #${index + 1} of ${total}` })
 * );
 */
export function numberOf<TReturn>(n: number, fn: (index: number, n: number) => TReturn) {
  return Array.from({ length: n }, (_, index) => fn(index, n));
}
