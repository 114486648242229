import { type AxiosError } from "axios";
import { useState } from "react";
import { Colors, Icon } from "swing-components";

import { AlertBoxError, ButtonScore } from "~components";
import { msg, SkillBuilderPayload } from "~utils";

import "./ModalSkillBuilderFeedback.css";

import { QuestionComputed, UnsyncedSkillBuilderAttribute } from "~skillBuilder/utils";
import { Question } from "../Question";

type FeedbackInFlightField = {
  isRequired?: boolean;
};

export type FeedbackQuestion = QuestionComputed & FeedbackInFlightField;

type ModalSkillBuilderFeedbackProps = {
  hasApiError?: AxiosError | null;
  onConfirm: (payload: SkillBuilderPayload) => void;
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
  questions: FeedbackQuestion[];
};

export function ModalSkillBuilderFeedback(props: ModalSkillBuilderFeedbackProps) {
  const { hasApiError, onConfirm, onDismiss, questions } = props;
  const [_questions, _setQuestions] = useState(questions);

  // Note: no need to query for existing data as always net new
  // only 1 feedback can be saved for the same task "group"

  function handleSubmit() {
    const questionAnswers = _questions.map((q) => ({
      attribute: q.attribute as SkillBuilderPayload[number]["attribute"],
      value: q.value || "valid", // to handle when optional text input is left blank
    }));
    onConfirm(questionAnswers);
    clearQuestionAnswers(_questions);
  }

  function onChange({ attribute, value }: UnsyncedSkillBuilderAttribute) {
    const questionsCopy = [..._questions];
    const questionIdx = questionsCopy.findIndex((q) => q.attribute === attribute);
    if (questionIdx > -1) {
      questionsCopy[questionIdx].value = value;
    }
    _setQuestions(questionsCopy);
  }

  function handleDismiss() {
    // clear / "reset" all question answers
    const resetQuestionAnswers = clearQuestionAnswers(_questions);
    _setQuestions(resetQuestionAnswers);
    // invoke caller fn
    onDismiss();
  }

  function clearQuestionAnswers(questions: FeedbackQuestion[]) {
    // clear / "reset" all question answers
    const questionsCopy = [...questions];
    questionsCopy.forEach((q) => (q.value = undefined));
    return questionsCopy;
  }

  // disabled when there is an answer that is required and there is no answer
  const unansweredRequiredQuestions = _questions.filter((q) => q.isRequired && !q.value);
  const isSubmitButtonDisabled = unansweredRequiredQuestions.length > 0;

  return (
    <div className="modal-wrapper">
      <div className="modal-header">
        <h2>{msg("SKILL_BUILDER_FEEDBACK_TITLE")}</h2>
        {/* FIXME: when design system button is available */}
        <button onClick={handleDismiss} style={{ backgroundColor: "transparent" }}>
          <Icon name="Close" color={Colors.blue500} />
        </button>
      </div>
      <div className="modal-body">
        {hasApiError && <AlertBoxError />}
        {_questions.map((q, idx) => (
          <div key={q.attribute} style={{ marginBottom: idx !== _questions.length - 1 ? 32 : 0 }}>
            <Question {...q} value={q.value} onChange={onChange} isReadOnly={false} />
          </div>
        ))}
      </div>
      <div className="modal-footer">
        <div className="action-button">
          <ButtonScore fill="outline" label="Skip" onClick={handleDismiss} />
        </div>
        <div className="action-button">
          <ButtonScore
            disabled={isSubmitButtonDisabled}
            label={msg("SKILL_BUILDER_FEEDBACK_BUTTON_SUBMIT")}
            expand="block"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}
