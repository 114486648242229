import "globalSetUp";

import { FullStory, init as initFullStory } from "@fullstory/browser";
import { IonApp } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { ErrorBoundary, setUser as SentryReactSetUser } from "@sentry/react";
import { useEffect } from "react";

import { AlertBoxError, LoadingInterstitial, useAuth } from "~components";
import { AuthedRoutes, UnAuthedRoutes } from "~pages";
import { BUILD_PROFILE } from "./utils/build";

/********** Component **********/
/**
 * Swing Subs application entry point.
 */
export function App() {
  const { userInfo, status } = useAuth();

  useEffect(() => {
    if (userInfo.id === undefined) return;

    // set Sentry when user exists
    // https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/identify-user/
    SentryReactSetUser({ id: userInfo.id, email: userInfo.email });

    if (BUILD_PROFILE !== "prod") return;

    // set Pendo when user exists and in prod
    // https://agent.pendo.io/
    pendo.initialize({ visitor: { id: userInfo.id } });

    // set FullStory when user exists and in prod
    // https://github.com/fullstorydev/fullstory-browser-sdk
    initFullStory({ orgId: "GMJQ" });
    FullStory("setIdentity", {
      uid: userInfo.dbid?.toString(),
      properties: {
        displayName: userInfo.name,
        sub: true,
        ...userInfo,
      },
    });
  }, [userInfo]);

  /***** Render *****/
  return (
    <ErrorBoundary fallback={<AlertBoxError margin={16} />}>
      <IonApp>
        <IonReactRouter>
          {status === undefined && (
            <div
              style={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LoadingInterstitial />
            </div>
          )}
          {/* TODO: @KoltonG - Can we merge all routing at this level by using
          rules like the OnboardingRoutes? */}
          {status === "AUTHENTICATED" && <AuthedRoutes />}
          {status === "UN_AUTHENTICATED" && <UnAuthedRoutes />}
        </IonReactRouter>
      </IonApp>
    </ErrorBoundary>
  );
}
