import { useQueries } from "@tanstack/react-query";
import { useEffect } from "react";
import { ExtractRouteParams } from "react-router";
import { generatePath, RouteComponentProps } from "react-router-dom";
import { ScorePage } from "src/pages/authenticated/ScoreTemplates";

import { AlertBoxError, ContentSingleColumn } from "~components";
import { AUTH_URLS } from "~pages";
import { computeGroupsState, getConfiguration, Group } from "~skillBuilder/utils";
import { GET, SkillBuilderAttribute } from "~utils";
import { TasksPageBase } from "./TasksPageBase";

type SkillBuilderTasksPageProps = RouteComponentProps<
  ExtractRouteParams<(typeof AUTH_URLS)["skillBuilderTasks"], string>
>;

/***** Component *****/
export function SkillBuilderTasksPage(props: SkillBuilderTasksPageProps) {
  const { history, match } = props;
  const { version } = match.params;

  // To capture group name in order to open corresponding accordion
  const historyState = history.location.state as { groupName?: Group["name"] };

  /***** Queries ******/

  const { data: groupsComputed, errors } = useQueries({
    queries: [
      {
        queryKey: ["skillBuilderConfiguration", version],
        queryFn: () => getConfiguration(version),
        // Cache the configuration for the lifetime of the application since this
        // only changes on new deployments which will require an application refresh.
        // This will avoid all future calls using this key from making a network request.
        staleTime: Infinity,
      },
      {
        queryKey: ["skillBuilderAttributes"],
        queryFn: () => GET("/api/sub/skillbuilder"),
        // Since the response is `{ data: [...subAttribute] }` we can clean up the response
        select: (data) =>
          // @ts-expect-error - fix types
          data?.data.map((attribute) => ({
            ...attribute,
          })) as Array<SkillBuilderAttribute>,
      },
    ],
    // Note: using `combine` results in not being able to access other query properties, such as error
    // https://tanstack.com/query/v5/docs/framework/react/reference/useQueries#combine
    // therefore specifically returning these to be able to update UI accordingly
    combine: (results) => {
      const configuration = results[0].data;
      const skillBuilderAttributes = results[1].data;

      if (!configuration || !skillBuilderAttributes) {
        return {
          data: undefined,
          // `filter(Boolean)` to remove `null` when error does not exist
          errors: results.map((res) => res.error).filter(Boolean),
        };
      }

      return {
        data: computeGroupsState({
          groups: configuration.groups,
          syncedSkillBuilderAttributes: skillBuilderAttributes,
        }),
      };
    },
  });

  const isErrors = errors && errors.length > 0;

  useEffect(() => {
    // only run on mount to clear history state, which closes all accordions on refresh
    if (historyState?.groupName) delete historyState.groupName;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /***** Handlers *****/

  // Routes to the Task page
  function handleTaskClick(taskIndex: number) {
    history.push(generatePath(AUTH_URLS.skillBuilderTask, { version, task: taskIndex }));
  }

  /***** Render *****/

  return (
    <ScorePage title="Skill Builder" hasBack isLoading={!groupsComputed && !isErrors}>
      <ContentSingleColumn>
        {isErrors && <AlertBoxError />}
        {groupsComputed && (
          <TasksPageBase
            currentGroupName={historyState?.groupName}
            groupsComputed={groupsComputed}
            onTaskClick={handleTaskClick}
          />
        )}
      </ContentSingleColumn>
    </ScorePage>
  );
}
