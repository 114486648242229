import { ReactNode, useState } from "react";
import { Colors } from "swing-components";

import { Accordion, AccordionProps, Chip, ChipColor } from "~components";
import { EmptyTaskCard, TaskCard } from "~skillBuilder/components";
import { msg } from "~utils";
import { Group, GroupComputed, GroupStatus, TaskComputed } from "../utils";

/********** Components **********/
type TasksPageBaseProps = {
  currentGroupName?: Group["name"]; // related group name of last task viewed by user
  groupsComputed: GroupComputed[];
  onTaskClick: (taskIndex: number) => void;
};

export function TasksPageBase(props: TasksPageBaseProps) {
  const { currentGroupName, groupsComputed, onTaskClick } = props;

  /***** Handlers *****/

  function handleTaskClick(uniqueTaskName: string) {
    const taskIndex = groupsComputed
      .flatMap((group) => group.tasks)
      .findIndex((task) => task.uniqueName === uniqueTaskName);
    if (taskIndex !== undefined) {
      onTaskClick(taskIndex);
    } else {
      // eslint-disable-next-line no-console
      console.error(`Unique task name: ${uniqueTaskName} not found`);
    }
  }

  /***** Render *****/

  return (
    <div style={{ flexGrow: 1 }}>
      <header
        style={{
          padding: "24px 16px",
          alignSelf: "stretch",
          borderRadius: "8px 8px 0px 0px",
          boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.15)",
          marginBottom: "2px",
          background: Colors.white200,
        }}
      >
        <p style={{ fontSize: "16px" }}>{msg("SKILL_BUILDER_SUB_TEXT")}.</p>
      </header>
      {groupsComputed.map((group) => {
        return (
          <AccordionWrapper
            key={group.name}
            title={group.name}
            startsOpen={group.name === currentGroupName ? true : false}
            status={group.status}
          >
            {TaskCards({
              tasks: group.tasks,
              onTaskClick: handleTaskClick,
              emptyTitle: msg("SKILL_BUILDER_EMPTY_TASK_TITLE"),
              emptyDesc: msg("SKILL_BUILDER_EMPTY_TASK_DESCRIPTION"),
            })}
          </AccordionWrapper>
        );
      })}
    </div>
  );
}

/********** Helper Components **********/

function AccordionTitle(props: { title: ReactNode; status: GroupStatus }) {
  const { title, status } = props;

  const chipMapper: Record<GroupStatus, { color: ChipColor; name: string }> = {
    COMING_SOON: {
      color: "SLATE",
      name: "Coming Soon",
    },
    NOT_STARTED: {
      color: "YELLOW",
      name: "Start",
    },
    IN_PROGRESS: {
      color: "PURPLE",
      name: "In Progress",
    },
    COMPLETE: {
      color: "GREEN",
      name: "Complete",
    },
  };

  return (
    <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
      <span style={{ fontWeight: "bold" }}>{title}</span>
      <Chip color={chipMapper[status].color} label={chipMapper[status].name} />
    </div>
  );
}

function AccordionWrapper(
  props: Pick<AccordionProps, "title" | "children"> & { startsOpen: boolean; status: GroupStatus },
) {
  const { title, children, startsOpen, status } = props;

  /***** Hooks *****/
  const [isOpen, setIsOpen] = useState(startsOpen);

  /***** Handlers *****/
  const handleOnClick = () => {
    setIsOpen(!isOpen);
  };

  /***** Render *****/
  return (
    <Accordion
      isOpen={isOpen}
      title={<AccordionTitle title={title} status={status} />}
      onClick={handleOnClick}
    >
      {children}
    </Accordion>
  );
}

function TaskCards(props: {
  tasks: TaskComputed[];
  emptyTitle: string;
  emptyDesc: string;
  onTaskClick?: (uniqueTaskName: string) => void;
}) {
  const { tasks, emptyTitle, emptyDesc, onTaskClick } = props;

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "24px",
      }}
    >
      {tasks.length === 0 && (
        <div style={{ width: "100%" }}>
          <EmptyTaskCard title={emptyTitle} description={emptyDesc} />
        </div>
      )}
      {tasks.length !== 0 &&
        tasks.map((task) => {
          const isTaskDisabled = task.status === "LOCKED";
          return (
            <div style={{ width: "100%" }} key={task.uniqueName}>
              <TaskCard
                {...{
                  ...task,
                  ...(!isTaskDisabled && onTaskClick
                    ? { onClick: () => onTaskClick(task.uniqueName) }
                    : {}),
                  disabled: isTaskDisabled,
                }}
              />
            </div>
          );
        })}
    </div>
  );
}
