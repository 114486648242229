import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { useState } from "react";

import { RadioInput } from "~components";
import { WebinarDatesAnswerType } from "~onboarding/utils";
import { GET, WebinarOption } from "~utils";

type WebinarDatesAnswerProps = Omit<WebinarDatesAnswerType, "type"> & {
  /**
   * The chosen webinar `id` and `date`.
   */
  value?: WebinarOption;
  /**
   * When selecting a webinar option, pass the `value` to the parent.
   */
  onChange: (value: WebinarOption) => void;
  /**
   * Disables the ability to chose a webinar date.
   */
  isReadOnly?: boolean;
};
export function WebinarDatesAnswer(props: WebinarDatesAnswerProps) {
  const { value, onChange, isReadOnly } = props;

  /***** Queries *****/
  const { data: webinarDates } = useQuery({
    // Disable the query in read-only mode since no selection is allowed.
    enabled: !isReadOnly,
    queryKey: ["webinarDates"],
    queryFn: () => GET("/api/sub/webinars"),
    select: (data) => data.data,
  });

  /***** Handlers *****/
  function handleChange(webinarOption: WebinarOption) {
    onChange({
      id: webinarOption.id,
      date: webinarOption.date,
    });
  }

  /***** Render *****/
  return (
    <WebinarDatesAnswerBase
      options={webinarDates ?? []}
      value={value}
      onChange={handleChange}
      isReadOnly={isReadOnly}
    />
  );
}

type WebinarDatesAnswerBaseProps = {
  options: Array<WebinarOption>;
  isReadOnly?: boolean;
  value?: WebinarOption;
  onChange: (value: WebinarOption) => void;
};
export function WebinarDatesAnswerBase(props: WebinarDatesAnswerBaseProps) {
  const { options, value, isReadOnly, onChange } = props;

  /***** State *****/
  // Stores the `value` on mount as this option could represent a previously
  // selected option that is no longer available.
  // e.g. 5 days ago you chose a webinar data X, then today you come back to
  // this answer type and that date is no longer available. The user would still
  // expect to see the previously selected date in the list.
  const [previousValue] = useState<WebinarOption | undefined>(value);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
      {/* Show selected option */}
      {previousValue && !options.find((o) => o.id === previousValue.id) && (
        <div style={{ padding: "16px 24px" }} key={previousValue.id}>
          <RadioInput
            isDisabled={isReadOnly}
            isSelected={value?.id === previousValue.id}
            onChange={() => onChange(previousValue)}
            label={formatWebinarDate(previousValue.date)}
            value={previousValue.id.toString()}
          />
        </div>
      )}
      {/* Show the available options */}
      {options.map((option) => (
        <div style={{ padding: "16px 24px" }} key={option.id}>
          <RadioInput
            isDisabled={isReadOnly}
            isSelected={value?.id === option.id}
            onChange={() => onChange(option)}
            label={formatWebinarDate(option.date)}
            value={option.id.toString()}
          />
        </div>
      ))}
    </div>
  );
}

/********** Helper Functions *********/
export function formatWebinarDate(date: string) {
  // Formatting documentation: https://moment.github.io/luxon/#/formatting?id=table-of-tokens
  return DateTime.fromISO(date).toFormat("EEEE, MMMM d 'at' h:mm a");
}
