import { AxiosError } from "axios";
import { useState } from "react";
import {
  convertPreferencesToDisplay,
  convertPreferencesToSave,
  gradeLevelMappings,
  GradeLevelValueProps,
} from "swing-components";

import {
  AlertBoxError,
  ModalSubProfileEdit,
  ModalSubProfileEditBody,
  ModalSubProfileEditFooter,
  ModalSubProfileEditHeader,
} from "~components";
import { Question } from "~onboarding/components";
import { QUESTION_GRADE_LEVELS, UnsyncedSubAttribute } from "~onboarding/utils";
import { msg } from "~utils";

type ModalContentClassroomPreferencesGradeLevelEditProps = {
  gradeLevels?: GradeLevelValueProps;
  hasApiError?: AxiosError | null;
  onConfirm: (gradeLevels: GradeLevelValueProps) => void;
  onDismiss: (data?: string | number, role?: string) => void;
  title: string;
};

export function ModalContentClassroomPreferencesGradeLevelEdit(
  props: ModalContentClassroomPreferencesGradeLevelEditProps,
) {
  const { gradeLevels, hasApiError, onConfirm, onDismiss, title } = props;
  const [_gradeLevels, _setGradeLevels] = useState<string[]>(
    convertPreferencesToDisplay(gradeLevels, gradeLevelMappings.valueTypes) ?? [],
  );
  const _originalGradeLevels = gradeLevels ?? [];
  const isSaveDisabled =
    _gradeLevels.length <= 0 || isSameArrayContent(_originalGradeLevels, _gradeLevels);

  function onChange({ value }: UnsyncedSubAttribute) {
    if (value) {
      const val = value as string;
      const convertedVals = JSON.parse(val).map((value: string) => value);
      _setGradeLevels(convertedVals);
    } else {
      _setGradeLevels([]);
    }
  }

  function handleOnSave() {
    // join is for making the individual lists one big list to then split over to get broken out grade levels
    const convertedGrades = convertPreferencesToSave(_gradeLevels, gradeLevelMappings.datomic)
      .join(",")
      .split(",");
    onConfirm(convertedGrades);
  }

  return (
    <ModalSubProfileEdit>
      <ModalSubProfileEditHeader onDismiss={onDismiss} title={title} />
      <ModalSubProfileEditBody>
        {!!hasApiError && <AlertBoxError />}
        <div>
          <Question
            {...QUESTION_GRADE_LEVELS}
            value={JSON.stringify(_gradeLevels)}
            onChange={onChange}
          />
        </div>
      </ModalSubProfileEditBody>
      <ModalSubProfileEditFooter
        buttonPrimary={{
          isDisabled: isSaveDisabled,
          text: msg("LABEL_SAVE"),
          onClick: () => handleOnSave(),
        }}
      />
    </ModalSubProfileEdit>
  );
}

export function isSameArrayContent(arr1: string[], arr2: string[]) {
  const sortedArr1 = arr1.sort();
  const sortedArr2 = arr2.sort();
  return (
    arr1.length === arr2.length &&
    sortedArr1.every((element, index) => element === sortedArr2[index])
  );
}
