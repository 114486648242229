import { jsxs as c, jsx as n } from "react/jsx-runtime";
import "@ionic/react";
import "../../../AuthProvider-eYZfUA7K.js";
import "react";
import "../../../react-paginate-Lh5zQzpz.js";
import { ButtonEdit as d } from "../../organisms/SubProfile/components/ButtonEdit/ButtonEdit.js";
import '../../../css/IconBlock.css';const a = "_row_19rrp_1", l = "_divider_19rrp_29", r = {
  row: a,
  "icon-none": "_icon-none_19rrp_7",
  "icon-left": "_icon-left_19rrp_11",
  "icon-right": "_icon-right_19rrp_15",
  "icon-both": "_icon-both_19rrp_19",
  "button-edit": "_button-edit_19rrp_24",
  divider: l
};
function N(t) {
  const {
    content: o,
    hasDivider: s,
    icon: e,
    onClick: i
  } = t, _ = p(e, i);
  return /* @__PURE__ */ c("div", {
    children: [/* @__PURE__ */ c("div", {
      className: `${r.row} ${_}`,
      children: [e, o, i ? /* @__PURE__ */ n("span", {
        className: r["button-edit"],
        children: /* @__PURE__ */ n(d, {
          onClick: i
        })
      }) : null]
    }), s && /* @__PURE__ */ n("div", {
      className: r.divider
    })]
  });
}
function p(t, o) {
  return !t && !o ? r["icon-none"] : t && !o ? r["icon-left"] : !t && o ? r["icon-right"] : r["icon-both"];
}
export {
  N as IconBlock,
  p as generateLayoutClassName
};
