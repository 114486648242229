import { Colors, Icon, IconName } from "swing-components";

/********** Component **********/
export type MobileNavigationProps = {
  menuItems: MenuItemProps[];
};

/**
 * Mimics the Ionic tab bar, however, without the limitation of having routing
 * tied directly to the component.
 * @param props
 * @returns
 */
export function MobileNavigation(props: MobileNavigationProps) {
  const { menuItems } = props;

  return (
    <nav
      style={{
        backgroundColor: Colors.blueStone200,
        paddingLeft: 16,
        paddingRight: 16,
        height: "calc(56px + var(--ion-safe-area-bottom, 0))",
        display: "flex",
        flexDirection: "row",
        gap: 24,
        alignItems: "center",
        justifyContent: "space-evenly",
        paddingBottom: "var(--ion-safe-area-bottom, 0)",
      }}
    >
      {menuItems.map((menuItemProps) => (
        <MenuItem key={menuItemProps.label} {...menuItemProps} />
      ))}
    </nav>
  );
}

/********** Helper Component *******/
export type MenuItemProps = {
  onClick: () => void;
  iconName: IconName;
  label: string;
  isActive: boolean;
};

export function MenuItem(props: MenuItemProps) {
  const { onClick, iconName, label, isActive } = props;

  /***** Constants *****/
  const color = !isActive ? Colors.slate200 : Colors.yellow500;

  /***** Render *****/
  return (
    <button
      onClick={onClick}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "transparent",
      }}
    >
      <Icon name={iconName} color={color} />
      {/* TODO: Maybe the Desktop should also use span */}
      <span style={{ fontSize: 12, fontWeight: 600, color }}>{label}</span>
    </button>
  );
}
