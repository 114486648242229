import { useMutation, useQuery } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { useEffect, useReducer, useRef } from "react";
import { ExtractRouteParams, generatePath, RouteComponentProps } from "react-router";
import { components } from "src/__generated__/api";
import { AUTH_URLS } from "src/pages/authenticated/Routes";
import { ScorePage } from "src/pages/authenticated/ScoreTemplates";

import { AlertBoxError, ContentSingleColumn, QUERY_CLIENT, useModal } from "~components";
import { ModalSkillBuilderFeedback, SkillBuilderPage } from "~skillBuilder/components";
import { getConfiguration } from "~skillBuilder/utils";
import { replacePath } from "~skillBuilder/utils/helpers";
import { GET, POST, SkillBuilderPayload } from "~utils";
import { TaskPageReducer, TaskPageReducerState } from "./Task.page.reducer";
import { TaskPageBase } from "./TaskPageBase";

type SkillBuilderTaskPageProps = RouteComponentProps<
  ExtractRouteParams<(typeof AUTH_URLS)["skillBuilderTask"], string>
>;

/***** Component *****/
export function SkillBuilderTaskPage(props: SkillBuilderTaskPageProps) {
  const { history, match } = props;
  const { version, task: taskIndex, step: stepIndex } = match.params;

  /***** State *****/

  // avoid race condition of 2 instances of the modal being rendered
  // when opened resulting in user needed to "close" the modal twice
  const isModalRendered = useRef(false);
  // store the quiz last step's index so that when feedback modal
  // is opened and step is completed we can keep user on the last
  // step when the feedback modal is displayed
  const quizLastStepIndex = useRef<number | undefined>();

  // avoid duplicate POST calls (in dev mode due to useReducer rendered twice)
  const isSubmitFnInitialized = useRef(false);

  // handle feedback error specifically since we want to show an error in the
  // modal if attempting to save feedback results in failure and upon close of
  // the modal avoid displaying the actual POST error on the Task Step
  const isSaveFeedbackError = useRef(false);

  // Note: in React strict mode `useReducer` is rendered twice
  // https://react.dev/reference/react/useReducer#my-reducer-or-initializer-function-runs-twice
  // Note: fns that are initialized via useReducer and attempt to reference variables directly within
  // this component will be closed over leading to stale values. Therefore, either pass via `payload`
  // or call out to another function within this component that is not initialized via useReducer to
  // access the variable value at the time in which it is needed
  const [state, dispatch] = useReducer(TaskPageReducer, {
    // Setting the initial state to the URL value if it exists
    stepIndex: {
      initial: quizLastStepIndex.current
        ? quizLastStepIndex.current
        : stepIndex
          ? parseInt(stepIndex)
          : undefined,
    },
    // Set to true since we don't have the `taskComputed` field yet
    isLoading: true,
    // Handlers
    onClose: handleClose,
    onSubmit: handleSubmit,
    onStepChange: handleStepChange,
    onQuizLastStep: handleQuizLastStep,
  } satisfies TaskPageReducerState);

  /***** Queries *****/

  // Get configuration data or error if it exists
  const { data: config, error: configError } = useQuery({
    queryKey: ["skillBuilderConfiguration", version],
    queryFn: () => getConfiguration(version),
    // Cache the configuration for the lifetime of the application since this
    // only changes on new deployments which will require an application refresh.
    // This will avoid all future calls using this key from making a network request.
    staleTime: Infinity,
    select: (configuration) => {
      const groups = configuration.groups;
      const task = groups.flatMap((group) => group.tasks)[parseInt(taskIndex)];
      const taskGroup = groups.find((g) => g.tasks.find((t) => t.uniqueName === task.uniqueName));
      return {
        task: configuration.groups.flatMap((group) => group.tasks)[parseInt(taskIndex)],
        feedback: taskGroup?.feedback,
        taskGroup,
      };
    },
  });

  // Save the task in state
  useEffect(
    () => config?.task && dispatch({ type: "SET_TASK", payload: { task: config?.task } }),
    [config?.task],
  );

  // Get the skill builder attributes that are used to calculate the progress of each Task
  const { data: skillBuilderAttributes, error: attributesError } = useQuery({
    queryKey: ["skillBuilderAttributes"],
    queryFn: () => GET("/api/sub/skillbuilder"),
    // Since the response is `{ data: [...skillBuilderAttribute] }` we can clean up the response
    select: (data) => data?.data.map((attribute) => ({ ...attribute })),
  });

  // Save the skill builder attributes in state
  useEffect(
    () =>
      skillBuilderAttributes &&
      dispatch({
        type: "SET_SKILL_BUILDER_ATTRIBUTES",
        payload: { skillBuilderAttributes, quizLastStep: quizLastStepIndex.current },
      }),
    // Using `JSON.stringify` to compare the array of skill builder attributes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(skillBuilderAttributes)],
  );

  /***** Mutations *****/

  const { mutateAsync: submitSkillBuilderAnswers, error: saveError } = useMutation<
    { data: components["schemas"]["SkillBuilderAttr"][] },
    AxiosError,
    SkillBuilderPayload
  >({
    mutationKey: ["submitSkillBuilderAnswers"],
    mutationFn: (answers) => POST("/api/sub/skillbuilder", answers),
    onSuccess: (newSkillBuilderAttribute) => {
      // Update the cache with the new skill builder attributes
      QUERY_CLIENT.setQueryData(["skillBuilderAttributes"], newSkillBuilderAttribute);
      // Reset handle submit so function body will execute
      isSubmitFnInitialized.current = false;
      // If we are on the last step in the task then we have 2 flows:
      // - 1) if NOT a task quiz step then return to tasks page
      // - 2) if IS a task quiz step then show feedback modal
      const isLastStepInTask = calcIsLastStepInTask(state.stepIndex?.current);
      if (isLastStepInTask && !config?.task.isQuiz) {
        // return to tasks page
        handleClose();
      }
      if (isLastStepInTask && config?.task.isQuiz) {
        // keep user on last step of quiz task
        quizLastStepIndex.current = state.stepIndex?.current;
        // open feedback modal
        // Note: we don't need to check if feedback modal is complete here since we expect the user
        // to not have seen the feedback modal yet since they are now just completing the last step
        // in the task quiz which is when they are able to see the modal
        // Note: we also need to have code in the task reducer since this only will trigger upon completion
        // of the last task quiz step. The user may close the modal without completing and come back to the
        // last task quiz step at any time so should be able to view the feedback modal until it is completed
        openModal();
      }
    },
  });

  /******** Feedback Modal *********/

  const { openModal, closeModal } = useModal({
    component: (
      <ModalSkillBuilderFeedback
        onDismiss={() => {
          isModalRendered.current = false;
          quizLastStepIndex.current = undefined;
          // close modal
          closeModal();
        }}
        questions={config?.feedback || []}
        onConfirm={async (answers) => {
          // save answers
          await submitSkillBuilderAnswers(answers)
            .then(() => {
              // close modal
              closeModal();
              // navigate to tasks page
              handleClose();
            })
            // set error
            .catch(() => {
              isSaveFeedbackError.current = true;
            });
        }}
        hasApiError={saveError}
      />
    ),
    modalOptions: { cssClass: "modal-skill-builder-feedback" },
  });

  /***** Handlers *****/

  function calcIsLastStepInTask(currentStep?: number) {
    // null check since currentStep could be 0
    if (currentStep !== null && config?.task.steps) {
      if (currentStep === config.task.steps.length - 1) {
        return true;
      }
      return false;
    }
    return false;
  }

  function handleSubmit(state: Parameters<TaskPageReducerState["onSubmit"]>[0]) {
    if (!isSubmitFnInitialized.current) {
      isSubmitFnInitialized.current = true;
      return submitSkillBuilderAnswers(
        // @ts-expect-error - This is having a hard time handing the
        // discriminant union type.
        state.unsyncedSkillBuilderAttributes.map((usba) => ({
          attribute: usba.attribute,
          value: usba.value,
        })),
      );
    }
  }

  function handleStepChange(newStepIndex: number) {
    const newUrl = generatePath(AUTH_URLS.skillBuilderTask, {
      version,
      task: taskIndex,
      step: newStepIndex,
    });
    replacePath(newUrl);
  }

  function handleClose() {
    history.push(generatePath(AUTH_URLS.skillBuilderTasks, { version }), {
      groupName: config?.taskGroup?.name,
    });
  }

  function handleQuizLastStep(isFeedbackComplete: boolean) {
    // if user has already completed this group's feedback then go back to tasks
    if (isFeedbackComplete) {
      handleClose();
      return;
    }
    // otherwise show feedback modal
    // note: avoid initializing more than 1 modal in
    // dev mode due to useReducer being rendered twice
    if (!isModalRendered.current) {
      isModalRendered.current = true;
      openModal();
    }
  }

  /***** Render *****/

  if (configError || attributesError) {
    return (
      <SkillBuilderPage>
        <ContentSingleColumn>
          <AlertBoxError />
        </ContentSingleColumn>
      </SkillBuilderPage>
    );
  }

  if (!state.taskComputed || state.stepIndex?.current === undefined) {
    return <SkillBuilderPage isLoading={true} />;
  }

  const isShowPreviousButton = config?.task?.steps[state.stepIndex.current].showPreviousButton;

  return (
    <ScorePage title={config?.taskGroup?.name || ""}>
      <ContentSingleColumn>
        {!isSaveFeedbackError.current && saveError && <AlertBoxError />}
        <TaskPageBase
          taskComputed={state.taskComputed}
          stepIndex={state.stepIndex?.current}
          isLoading={state.isLoading}
          isReadOnly={state.isReadOnly}
          onAnswerChange={(skillBuilderAttribute) =>
            dispatch({
              type: "UPDATE_ANSWER",
              payload: { skillBuilderAttribute },
            })
          }
          onNextButtonClick={() =>
            dispatch({ type: "NEXT_BUTTON", payload: { feedback: config?.feedback } })
          }
          onPrevButtonClick={() =>
            dispatch({ type: "PREV_BUTTON", payload: { isShowPreviousButton } })
          }
          onClose={handleClose}
        />
      </ContentSingleColumn>
    </ScorePage>
  );
}
