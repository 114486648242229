import { CSSProperties } from "react";
import { Colors } from "swing-components";

const iconSize = "1.25rem";
const borderWidth = "0.125rem";

const styles = {
  container: ({ isDisabled }: Pick<RadioInputProps, "isDisabled">) =>
    ({
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: 16,
      background: Colors.white200,
      cursor: isDisabled ? "not-allowed" : "pointer",
    }) as CSSProperties,
  iconWrapper: {
    display: "flex",
    lineHeight: "normal",
  },
  radioIconOuter: ({ isDisabled }: Pick<RadioInputProps, "isDisabled">) =>
    ({
      width: iconSize,
      height: iconSize,
      margin: 0,
      position: "relative",
      borderColor: isDisabled ? Colors.slate300 : Colors.blue500,
      borderRadius: "50%",
      borderWidth: borderWidth,
      borderStyle: "solid",
      display: "inline-block",
    }) as CSSProperties,
  radioIconInner: ({
    isSelected,
    isDisabled,
  }: Pick<RadioInputProps, "isSelected" | "isDisabled">) =>
    ({
      position: "absolute",
      top: "50%",
      left: "50%",
      borderRadius: "50%",
      width: `calc(50% + ${borderWidth})`,
      height: `calc(50% + ${borderWidth})`,
      background: isDisabled ? Colors.slate300 : Colors.blue500,
      transition: "transform 280ms cubic-bezier(0.4, 0, 0.2, 1) 0s",
      transform: isSelected
        ? "translate(-50%, -50%) scale3d(1, 1, 1)"
        : "translate(-50%, -50%) scale3d(0, 0, 0)",
    }) as CSSProperties,
};

type RadioInputProps = {
  isSelected?: boolean;
  label?: string;
  value: string;
  isDisabled?: boolean;
  onChange?: (value: string) => void;
};

export function RadioInput(props: RadioInputProps) {
  const { isDisabled, isSelected, value, label, onChange = () => {} } = props;

  return (
    <button
      data-testid="button-radio"
      style={styles.container({ isDisabled })}
      aria-checked={isSelected}
      tabIndex={0}
      onClick={() => {
        if (!isDisabled && !isSelected) {
          onChange(value);
        }
      }}
    >
      <div style={styles.iconWrapper}>
        <div style={styles.radioIconOuter({ isDisabled })}>
          <div style={styles.radioIconInner({ isSelected, isDisabled })} />
        </div>
      </div>
      <p
        style={{
          fontSize: 16,
          color: isDisabled ? Colors.slate300 : Colors.black,
          fontFamily: "Poppins",
          textAlign: "left",
          lineHeight: "normal",
          margin: 0,
        }}
      >
        {label}
      </p>
    </button>
  );
}
