import { createRef, Ref, RefObject } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { FEATURE_FLAGS } from "src/utils/feature-flags";
import { Colors } from "swing-components";

import { goToTop, LoadingInterstitial, MenuItemProps, Page, PageProps, useAuth } from "~components";
import {
  AUTH_URLS,
  createHelpUrl,
  createMoreUrl,
  createMyRequestsUrl,
  createProfileUrl,
  createReferralProgramUrl,
  createRequestsUrl,
} from "~pages";
import { msg } from "~utils";
import { Header } from "./Header";
import styles from "./ScorePage.module.css";

type ScorePageProps = Pick<PageProps, "children" | "isLoading"> & {
  title: string;
  hasBack?: boolean;
};

/**
 * Wrapper for the `Page` component that includes the onboarding navigation.
 *
 * TODO: @KoltonG - We could make this also take in route props to reduce the hooks usages.
 */
export function ScorePage(props: ScorePageProps) {
  const { title, isLoading = false, children, hasBack = false } = props;
  const ref: Ref<HTMLIonContentElement> = createRef();

  /***** Hooks *****/
  // Used to determine if we are on the proper page URL and if so highlight the
  // menu item.
  const openingsPageMatch = useRouteMatch(AUTH_URLS["requests"]);
  const myRequestsPageMatch = useRouteMatch(AUTH_URLS["myRequests"]);
  const morePageMatch = useRouteMatch(AUTH_URLS["more"]);
  const helpPageMatch = useRouteMatch(AUTH_URLS["help"]);
  const profilePageMatch = useRouteMatch(AUTH_URLS["profile"]);
  const referralProgramPageMatch = useRouteMatch(AUTH_URLS.referralProgram);

  // Used to navigate to different pages.
  const history = useHistory();
  const { signOut, userInfo } = useAuth();

  const isAliased = userInfo.isAlias;

  /***** Render *****/
  // TODO: This approach is needed to conditionally render the desktop `referral program` navigation but can remove this approach and directly set these items for the`mobileNavigationProps` once referral feature is released.
  function conditionalDesktopMenuItems(ref: RefObject<HTMLIonContentElement>) {
    const topMenuItems: MenuItemProps[] = [
      {
        iconName: "List",
        label: msg("NAV_OPENINGS"),
        isActive: !!openingsPageMatch?.isExact,
        onClick: async () => {
          if (openingsPageMatch?.isExact) {
            await goToTop(ref);
          } else {
            history.push(createRequestsUrl());
          }
        },
      },
      {
        iconName: "Calendar Check",
        label: msg("NAV_MY_REQUESTS"),
        isActive: !!myRequestsPageMatch?.isExact,
        onClick: async () => {
          if (myRequestsPageMatch?.isExact) {
            await goToTop(ref);
          } else {
            history.push(createMyRequestsUrl());
          }
        },
      },
    ];

    const bottomMenuItems: MenuItemProps[] = [
      {
        iconName: "Menu Meatball",
        label: msg("NAV_MORE"),
        isActive: !!morePageMatch?.isExact,
        onClick: async () => {
          if (morePageMatch?.isExact) {
            await goToTop(ref);
          } else {
            history.push(createMoreUrl());
          }
        },
      },
      {
        iconName: "Help",
        label: msg("MORE_HELP"),
        isActive: false,
        onClick: async () => {
          if (helpPageMatch?.isExact) {
            await goToTop(ref);
          } else {
            history.push(createHelpUrl());
          }
        },
      },
      {
        iconName: "Logout",
        label: msg("MORE_LOGOUT"),
        isActive: false,
        onClick: () => signOut({ history }),
      },
    ];

    if (FEATURE_FLAGS.REFERRAL) {
      const referralProgramMenuItem: MenuItemProps = {
        iconName: "Group",
        label: msg("MORE_REFERRAL_PROGRAM"),
        isActive: !!referralProgramPageMatch?.isExact,
        onClick: async () => {
          if (referralProgramPageMatch?.isExact) {
            await goToTop(ref);
          } else {
            history.push(createReferralProgramUrl());
          }
        },
      };
      // Insert Referral Program before Logout
      bottomMenuItems.splice(bottomMenuItems.length - 1, 0, referralProgramMenuItem);
    }
    return { top: topMenuItems, bottom: bottomMenuItems };
  }

  return (
    <Page
      reference={ref}
      isAliased={isAliased}
      aliasEmail={userInfo.email}
      desktopNavigationProps={{
        menuItems: conditionalDesktopMenuItems(ref),
        profile: {
          name: userInfo.name,
          imageUrl: userInfo.imageUrl,
          isActive: profilePageMatch?.isExact,
          onClick: async () => {
            if (profilePageMatch?.isExact) {
              await goToTop(ref);
            } else {
              history.push(createProfileUrl());
            }
          },
        },
      }}
      mobileNavigationProps={{
        menuItems: [
          {
            iconName: "List",
            label: msg("NAV_OPENINGS"),
            isActive: !!openingsPageMatch?.isExact,
            onClick: async () => {
              if (openingsPageMatch?.isExact) {
                await goToTop(ref);
              } else {
                history.push(createRequestsUrl());
              }
            },
          },
          {
            iconName: "Calendar Check",
            label: msg("NAV_MY_REQUESTS"),
            isActive: !!myRequestsPageMatch?.isExact,
            onClick: async () => {
              if (myRequestsPageMatch?.isExact) {
                await goToTop(ref);
              } else {
                history.push(createMyRequestsUrl());
              }
            },
          },
          {
            iconName: "Person",
            label: msg("NAV_PROFILE"),
            isActive: !!profilePageMatch?.isExact,
            onClick: async () => {
              if (profilePageMatch?.isExact) {
                await goToTop(ref);
              } else {
                history.push(createProfileUrl());
              }
            },
          },
          {
            iconName: "Menu Meatball",
            label: msg("NAV_MORE"),
            isActive: !!morePageMatch?.isExact,
            onClick: async () => {
              if (morePageMatch?.isExact) {
                await goToTop(ref);
              } else {
                history.push(createMoreUrl());
              }
            },
          },
        ],
      }}
    >
      <div className={styles["score-page-wrapper"]} style={{ color: Colors.black }}>
        <div className={styles["score-page-header-wrapper"]}>
          <Header title={title} hasBack={hasBack} />
        </div>
        {isLoading ? (
          <div className={styles["loading-interstitial-score"]}>
            <LoadingInterstitial />
          </div>
        ) : (
          <div className={styles["score-page-content-wrapper"]}>{children}</div>
        )}
      </div>
    </Page>
  );
}
