import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { FEATURE_FLAGS } from "src/utils/feature-flags";
import { AlertBox } from "swing-components";

import { ContentSingleColumn, OpeningsCard, useAuth } from "~components";
import { GET, msg, OpeningsData, PendingConfirmData } from "~utils";
import { createRequestUrl } from "../Routes";
import { ScorePage } from "../ScoreTemplates/ScorePage";

// TODO: find a better way to avoid the refetchInterval
// from running when running tests (Storybook)
type RequestsProps = {
  isTest?: boolean;
};

export function Requests(props: RequestsProps) {
  const { isTest } = props;
  const { data, isLoading: isOpeningsLoading } = useQuery({
    queryKey: ["fetchOpenings"],
    queryFn: () => GET("/api/sub/request/openings"),
    throwOnError: true, // flag necessary for uncaught errors to bubble to App level ErrorBoundary
    refetchInterval: isTest ? false : 30 * 1000, // time in ms - seconds * milliseconds = 30 seconds
  });

  const { data: confirmRequests, isLoading: isConfirmPendingLoading } = useQuery({
    queryKey: ["fetchPendingConfirm"],
    queryFn: () => GET("/api/sub/request/pending-confirm"),
    throwOnError: true, // flag necessary for uncaught errors to bubble to App level ErrorBoundary
    refetchInterval: isTest ? false : 60 * 1000, // time in ms - seconds * milliseconds = 60 seconds
  });

  return (
    <ScorePage
      title={msg("PAGE_TITLE_OPENINGS")}
      isLoading={isOpeningsLoading || isConfirmPendingLoading}
    >
      <ContentSingleColumn>
        {/* TODO update with conditional for no data state when updating openings page */}
        {data?.data && (
          <RequestsView requests={data.data} confirmRequests={confirmRequests?.data} />
        )}
      </ContentSingleColumn>
    </ScorePage>
  );
}

export type RequestsViewProps = {
  requests: OpeningsData[];
  confirmRequests?: PendingConfirmData[];
};

export function RequestsView(props: RequestsViewProps) {
  const { requests = [], confirmRequests } = props;
  const {
    userInfo: { isPhoneNumberVerified, notificationsSettings },
  } = useAuth();

  const showPhoneVerificationAlert = isPhoneNumberVerified === false && requests.length > 0;
  // Until we implement the push feature, we will be showing an alert to users that currently have push enabled from the previous app
  const hasDismissedNotificationsAlert =
    window.localStorage.getItem("notificationsAlertDismissed") === "true";
  const showEnableNotificationsAlert =
    FEATURE_FLAGS.NOTIFICATIONS &&
    notificationsSettings?.push === true &&
    requests.length > 0 &&
    !hasDismissedNotificationsAlert;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          height: "calc(100% - 48px)",
          maxWidth: "900px",
        }}
      >
        <div style={{ margin: "0 auto" }}>
          {showEnableNotificationsAlert && <EnableNotificationsAlert />}
          {showPhoneVerificationAlert && <PhoneVerificationAlert />}
          {confirmRequests && <ConfirmRequests confirmRequests={confirmRequests} />}
          {requests.length ? (
            <div style={{ paddingBottom: "16px" }}>
              {requests.map((req) => (
                <div key={req.id} style={{ margin: "16px 16px 24px 16px" }}>
                  <OpeningsCard request={req} />
                </div>
              ))}
              <span style={{ display: "flex", justifyContent: "center", fontStyle: "italic" }}>
                {msg("LABEL_END_OF_PAGE")}!
              </span>
            </div>
          ) : (
            <OpeningsEmptyView />
          )}
        </div>
      </div>
    </div>
  );
}

export function OpeningsEmptyView() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "var(--spacing-lg)",
        width: "100%",
        maxWidth: "440px",
        margin: "0 auto",
        // padding bottom to account for when confirm alert box is shown also
        padding: "104px 16px 32px 16px",
        textAlign: "center",
      }}
    >
      <img
        src="assets/man-with-large-magnifying-glass.svg"
        alt={msg("SETUP_ILLUSTRATION_IMAGE_ALT")}
        style={{ maxWidth: "200px" }}
      />
      <span style={{ fontSize: "var(--swing-font-md)", fontWeight: "var(--swing-font-semibold)" }}>
        {msg("OPENINGS_NONE_AVAILABLE")}
      </span>
      <span style={{ fontSize: "var(--swing-font-md)" }}>{msg("OPENINGS_RETURN_LATER")}.</span>
    </div>
  );
}

type ConfirmRequestsProps = {
  confirmRequests: PendingConfirmData[];
};

export function ConfirmRequests(props: ConfirmRequestsProps) {
  const { confirmRequests } = props;

  return confirmRequests.map((request) => {
    const formattedStartDate = DateTime.fromISO(request.startDate).toFormat("LLL dd");
    const formattedEndDate = DateTime.fromISO(request.endDate).toFormat("LLL dd");
    const formattedExpirationDate = DateTime.fromISO(request.confirmExpirationDate).toFormat(
      "LLL, dd",
    );
    const formattedExpirationTime = DateTime.fromISO(request.confirmExpirationDate)
      .toFormat("t")
      .toLowerCase();

    return (
      <PendingConfirmationBanner
        key={request.id}
        endDate={formattedEndDate}
        expirationDate={formattedExpirationDate}
        expirationTime={formattedExpirationTime}
        schoolName={request.schoolName}
        startDate={formattedStartDate}
        url={createRequestUrl(request.id)}
      />
    );
  });
}

type PendingConfirmationBannerProps = {
  endDate: string;
  expirationDate: string;
  expirationTime: string;
  schoolName: string;
  startDate: string;
  url: string;
};

function PendingConfirmationBanner(props: PendingConfirmationBannerProps) {
  const { endDate, expirationDate, expirationTime, schoolName, startDate, url } = props;
  return (
    <div
      style={{
        margin: "16px",
      }}
    >
      <AlertBox showIcon color="success" title={msg("CONFIRM_REQUEST_TITLE")}>
        {msg("CONFIRM_REQUEST_MESSAGE_1", {
          schoolName,
          startDate,
          endDate,
        })}
        {", "}
        <Link to={url}>{msg("CONFIRM_REQUEST_MESSAGE_LINK_LABEL")}</Link>{" "}
        {msg("CONFIRM_REQUEST_MESSAGE_2")}{" "}
        <span style={{ fontWeight: "var(--swing-font-bold)" }}>{expirationDate}</span>{" "}
        {msg("CONFIRM_REQUEST_MESSAGE_3")}{" "}
        <span style={{ fontWeight: "var(--swing-font-bold)" }}>{expirationTime}</span>.
      </AlertBox>
    </div>
  );
}

export function PhoneVerificationAlert() {
  return (
    <div style={{ margin: "16px" }}>
      <AlertBox color="warning" title={msg("PHONE_VERIFICATION_ALERT_TITLE")} showIcon>
        <Link to="/profile">{msg("PHONE_VERIFICATION_ALERT_MSG_LINK_LABEL")}</Link>
        {` ${msg("PHONE_VERIFICATION_ALERT_MSG")}`}
      </AlertBox>
    </div>
  );
}

export function EnableNotificationsAlert() {
  return (
    <div style={{ margin: "16px" }}>
      <AlertBox color="warning" title={msg("NEVER_MISS_AN_OPPORTUNITY_ALERT_TITLE")} showIcon>
        <Link
          to="/more/notifications"
          onClick={() => window.localStorage.setItem("notificationsAlertDismissed", "true")}
        >
          {msg("NOTIFICATIONS_ALERT_MSG_LINK_LABEL")}
        </Link>
        {` ${msg("NOTIFICATIONS_ALERT_MSG")}`}
      </AlertBox>
    </div>
  );
}
