import type { SkillBuilderAttribute } from "~utils";
import type { Question, QuestionComputed, Step, StepComputed, Task, TaskComputed } from "./types";
import type { Group, GroupComputed, GroupStatus } from "./types/group";

/********** Types **********/
/**
 * Represents a local change in the shape of a Skill Builder Attribute where only the
 * `attribute` field is required, while all others are optional.
 *
 * NOTE:
 * - `value` field is optional, and omission of this field means that the value
 * has been removed.
 */
export type UnsyncedSkillBuilderAttribute = Pick<SkillBuilderAttribute, "attribute"> &
  Partial<Omit<SkillBuilderAttribute, "attribute">>;

/********** Group Computations **********/
/** Given static Groups and attributes, return the computed Groups */
export function computeGroupsState(params: {
  groups: Group[];
  syncedSkillBuilderAttributes?: Array<SkillBuilderAttribute>;
}): GroupComputed[] {
  const { groups, syncedSkillBuilderAttributes = [] } = params;

  return groups.reduce<GroupComputed[]>((groupComputed, group) => {
    const tasks = group.tasks.map((task) =>
      computeTaskState({ task, syncedSkillBuilderAttributes }),
    );

    // handle task `Quiz` status
    const taskQuizIndex = tasks.findIndex((t) => t.isQuiz);
    if (taskQuizIndex > -1) {
      const isTaskUnlocked = tasks
        .filter((task) => !task.isQuiz)
        .every((t) => t.status === "COMPLETED");
      tasks[taskQuizIndex].status = isTaskUnlocked ? tasks[taskQuizIndex].status : "LOCKED";
    }

    // pass through the static name value
    const name = group.name;

    let status: GroupStatus = "COMING_SOON";

    if (tasks.length > 0 && tasks.every((task) => task.status === "COMPLETED")) {
      status = "COMPLETE";
    } else if (tasks.length > 0 && tasks.some((task) => task.status === "COMPLETED")) {
      status = "IN_PROGRESS";
    } else if (tasks.length > 0 && tasks.every((task) => task.status !== "COMPLETED")) {
      status = "NOT_STARTED";
    } else if (tasks.length === 0) {
      status = "COMING_SOON";
    }

    return [
      ...groupComputed,
      {
        tasks,
        status,
        name,
      },
    ];
  }, [] as GroupComputed[]);
}

/********** Task Computations **********/
export function computeTaskState(params: {
  task: Task;
  syncedSkillBuilderAttributes?: Array<SkillBuilderAttribute>;
  unsyncedSkillBuilderAttributes?: Array<UnsyncedSkillBuilderAttribute>;
}): TaskComputed {
  const { task, syncedSkillBuilderAttributes = [], unsyncedSkillBuilderAttributes = [] } = params;

  const steps = task.steps
    .map((step) =>
      computeStepState({ step, syncedSkillBuilderAttributes, unsyncedSkillBuilderAttributes }),
    )
    // Filtering is required since Steps which are no longer valid due to
    // branching will be returned as `null`.
    // TODO: See if we can get rid of this since we don't have branching questions
    .filter((step) => step !== null);

  const status = computeTaskStatus(steps);
  const progress = computeTaskProgress({ steps, status });

  return { ...task, steps, status, progress };
}

/**
 * Returns the Task progress based on the answered questions / total questions.
 *
 * NOTE: We are using answered question instead of completed questions since
 * this progress calculation is performed with unsynced changes as well.
 */
export function computeTaskProgress(params: {
  steps: Array<StepComputed>;
  status?: TaskComputed["status"];
}): number {
  const { steps, status } = params;

  // If the status is `COMPLETED` then the progress should be 1 (100%)
  if (status === "COMPLETED") return 1;

  // Otherwise, the progress is the answered questions / total questions
  const questions = steps.flatMap((step) => step.questions);
  const totalQuestions = questions.length;
  const answeredQuestions = questions.filter((question) => question.value !== undefined).length;

  return answeredQuestions / totalQuestions;
}

/********** Step Computations **********/
/** Returns a StepComputed type */
export function computeStepState(params: {
  step: Step;
  syncedSkillBuilderAttributes?: Array<SkillBuilderAttribute>;
  unsyncedSkillBuilderAttributes?: Array<UnsyncedSkillBuilderAttribute>;
}): StepComputed | null {
  const { step, syncedSkillBuilderAttributes = [], unsyncedSkillBuilderAttributes = [] } = params;

  const questions = step.questions.map((question) =>
    computeQuestionState({
      question,
      syncedSkillBuilderAttribute: syncedSkillBuilderAttributes.find(
        (ssa) => ssa.attribute === question.attribute,
      ),
      unsyncedSkillBuilderAttribute: unsyncedSkillBuilderAttributes.find(
        (usa) => usa.attribute === question.attribute,
      ),
    }),
  );

  // Compute the remaining fields based on the computed Questions
  const status = computeStepStatus(questions);

  return { ...step, questions, status };
}

/** Computes the status of the a Step or Task */
export function computeStepStatus<TItem extends QuestionComputed>(
  items: Array<TItem>,
): TItem["status"] {
  const incompleteItems = items.filter((item) => item.status !== "COMPLETED");
  // When all Questions or Steps are `COMPLETED`, then the Step or Task is `COMPLETED`
  if (incompleteItems.length === 0) return "COMPLETED";
  // Otherwise, return the first Question or Step's status which is `undefined`
  return incompleteItems[0].status;
}

/** Computes the status of the a Step or Task */
export function computeTaskStatus<TItem extends StepComputed>(
  items: Array<TItem>,
): TItem["status"] {
  const incompleteItems = items.filter((item) => item.status !== "COMPLETED");
  // When all Questions or Steps are `COMPLETED`, then the Step or Task is `COMPLETED`
  if (incompleteItems.length === 0) return "COMPLETED";
  // Otherwise, return the first Question or Step's status which is `undefined`
  return incompleteItems[0].status;
}

/********** Question Computations **********/
/**
 * Given a static definition of a Question and maybe Skill Builder Attributes
 * return the computed version of the Question.
 *
 */
export function computeQuestionState(params: {
  question: Question;
  syncedSkillBuilderAttribute?: SkillBuilderAttribute;
  unsyncedSkillBuilderAttribute?: UnsyncedSkillBuilderAttribute;
}): QuestionComputed {
  const { question, syncedSkillBuilderAttribute, unsyncedSkillBuilderAttribute } = params;

  /** Map computed question fields between synced or unsynced skill builder attribute **/
  const status =
    syncedSkillBuilderAttribute?.value || unsyncedSkillBuilderAttribute?.value
      ? "COMPLETED"
      : undefined;
  const value = syncedSkillBuilderAttribute?.value ?? unsyncedSkillBuilderAttribute?.value;

  return {
    ...question,
    status,
    value,
  };
}
