import { IonProgressBar } from "@ionic/react";
import { Colors, Icon } from "swing-components";

import { Step } from "~skillBuilder/components";
import { TaskComputed, UnsyncedSkillBuilderAttribute } from "~skillBuilder/utils";

/********** Component **********/
type TaskPageBaseProps = {
  taskComputed: Pick<TaskComputed, "name" | "progress" | "status" | "steps" | "isIntro">;
  stepIndex: number;
  isLoading?: boolean;
  isReadOnly?: boolean;
  onAnswerChange: (attributes: UnsyncedSkillBuilderAttribute) => void;
  onNextButtonClick: () => void;
  onPrevButtonClick: () => void;
  onClose: () => void;
};

export function TaskPageBase(props: TaskPageBaseProps) {
  const {
    taskComputed,
    stepIndex,
    isLoading,
    isReadOnly,
    onAnswerChange,
    onNextButtonClick,
    onPrevButtonClick,
    onClose,
  } = props;
  const { name, progress, status, isIntro } = taskComputed;

  /***** Constants *****/
  const steps = taskComputed.steps;
  const step = steps[stepIndex];
  // Used to determine "next" button label
  const isLastStep = stepIndex === steps.length - 1 ? true : false;
  // Used to disable the "Next" button when not all questions are answered
  const isAllQuestionsAnswered = step.questions.every((question) => question.value !== undefined);
  // Used to disable the "Next" button when `isCorrect` is used for question answers AND answer is incorrect
  const QuestionAnswerRadio = step.questions.map((question) => {
    if (question.answerType.type === "RADIO") {
      const answer = question.answerType.options.find((opt) => opt.value === question.value);
      return answer;
    }
  })[0];
  const isQuestionAnswerCorrect =
    QuestionAnswerRadio && QuestionAnswerRadio.isCorrect !== undefined
      ? QuestionAnswerRadio.isCorrect
      : true;

  return (
    <>
      {/* When Task is an "intro" task then use a custom header */}
      {isIntro && (
        <div style={{ backgroundColor: Colors.white200 }}>
          <div
            style={{
              padding: "24px 16px 24px",
              marginBottom: 2,
              boxShadow: "0px 2px 4px 0px rgba(40, 60, 93, 0.16)",
            }}
          >
            <div>
              <div
                style={{ display: "flex", fontWeight: 500, gap: 8, cursor: "pointer" }}
                onClick={onClose}
              >
                <Icon name="Arrow Left" color={Colors.blue500} />
                <span style={{ fontWeight: 600 }}>{taskComputed.name}</span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          color: Colors.slate600,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: Colors.white200,
          flexGrow: 1,
          marginBottom: 40,
        }}
      >
        {!isIntro && (
          <TaskHeader name={name} progress={progress} status={status} onClose={onClose} />
        )}

        {/* Body */}
        <div
          style={{
            padding: "40px 16px 40px",
            width: "100%",
            backgroundColor: Colors.white200,
          }}
        >
          {/* Step Content Container */}
          <div
            style={{
              maxWidth: 660,
              margin: "0 auto",
              display: "flex",
              flexDirection: "column",
              gap: "40px",
            }}
          >
            {/* Step */}
            <Step
              stepComputed={step}
              onChange={onAnswerChange}
              isReadOnly={isReadOnly}
              isLastStep={isLastStep}
              nextButtonProps={{
                label: step.nextButtonText,
                onClick: onNextButtonClick,
                disabled: isAllQuestionsAnswered === false || isLoading || !isQuestionAnswerCorrect,
                isLoading,
              }}
              // Only render the "Previous" button when not on the first step OR when static step has `isShowPreviousButton: true`
              {...((stepIndex !== 0 || step.showPreviousButton) && {
                prevButtonProps: {
                  label: step.prevButtonText,
                  onClick: onPrevButtonClick,
                  // Do not allow the user to go to the previous step while
                  // we are waiting for a response from the API
                  disabled: isLoading,
                },
              })}
            />
          </div>
        </div>
      </div>
    </>
  );
}

/********** Helper Components *********/
type TaskHeaderProps = {
  name: TaskComputed["name"];
  progress: TaskComputed["progress"];
  status: TaskComputed["status"];
  onClose: () => void;
};

function TaskHeader(props: TaskHeaderProps) {
  const { name, onClose, progress, status } = props;

  return (
    <header style={{ width: "100%", boxShadow: `0px 2px 4px ${Colors.shadow}`, zIndex: 1 }}>
      <div
        style={{
          padding: "24px 16px 24px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h3 style={{ margin: 0 }}>{name}</h3>
          <button
            style={{
              background: Colors.white200,
              color: Colors.blue500,
              padding: "8px 16px",
              borderRadius: "4px",
              border: "none",
              cursor: "pointer",
              fontSize: 14,
              fontWeight: 500,
            }}
            onClick={() => onClose()}
          >
            Close
          </button>
        </div>
        <ProgressIndicator progress={progress} status={status} />
      </div>
    </header>
  );
}

type ProgressIndicatorProps = {
  progress: TaskComputed["progress"];
  status: TaskComputed["status"];
};

function ProgressIndicator(props: ProgressIndicatorProps) {
  const { progress, status } = props;
  const color = status === "COMPLETED" ? Colors.green500 : Colors.blue500;

  return (
    <IonProgressBar
      value={progress}
      style={{
        height: "4px",
        "--progress-background": color,
        "--background": Colors.slate200,
      }}
    />
  );
}
