import { AxiosError } from "axios";
import { useState } from "react";
import { convertPreferencesToSave, languageMapper, LanguageValueProps } from "swing-components";

import {
  AlertBoxError,
  isSameArrayContent,
  ModalSubProfileEdit,
  ModalSubProfileEditBody,
  ModalSubProfileEditFooter,
  ModalSubProfileEditHeader,
} from "~components";
import { Question } from "~onboarding/components";
import { QUESTION_LANGUAGES, UnsyncedSubAttribute } from "~onboarding/utils";
import { msg } from "~utils";

type ModalContentClassroomPreferencesLanguagesEditProps = {
  languages?: LanguageValueProps;
  hasApiError?: AxiosError | null;
  onConfirm: (languages: LanguageValueProps) => void;
  onDismiss: (data?: string | number, role?: string) => void;
  title: string;
};

export function ModalContentClassroomPreferencesLanguagesEdit(
  props: ModalContentClassroomPreferencesLanguagesEditProps,
) {
  const { languages, hasApiError, onConfirm, onDismiss, title } = props;
  const [_languages, _setLanguages] = useState<LanguageValueProps>(languages ?? []);
  const _originalLanguages = languages ?? [];
  const isSaveDisabled =
    _languages.length <= 0 || isSameArrayContent(_originalLanguages, _languages);

  function onChange({ value }: UnsyncedSubAttribute) {
    if (value) {
      const val = value as string;
      const convertedVals = convertPreferencesToSave(val, languageMapper);
      _setLanguages(convertedVals);
    } else {
      _setLanguages([]);
    }
  }

  return (
    <ModalSubProfileEdit>
      <ModalSubProfileEditHeader onDismiss={onDismiss} title={title} />
      <ModalSubProfileEditBody>
        {!!hasApiError && <AlertBoxError />}
        <div>
          <Question
            {...QUESTION_LANGUAGES}
            value={JSON.stringify(_languages)}
            onChange={onChange}
          />
        </div>
      </ModalSubProfileEditBody>
      <ModalSubProfileEditFooter
        buttonPrimary={{
          isDisabled: isSaveDisabled,
          text: msg("LABEL_SAVE"),
          onClick: () => onConfirm(_languages),
        }}
      />
    </ModalSubProfileEdit>
  );
}
