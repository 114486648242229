import type { JSX } from "@ionic/core/components";
import { createAnimation, IonButton, IonSpinner } from "@ionic/react";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { Colors } from "swing-components";

type buttonFill = "clear" | "outline" | "solid";

export type Button = JSX.IonButton & {
  /** The text to display on the button
   * Determines whether a button is displayed or not
   */
  label?: string;
  fill?: buttonFill;
  submitted?: boolean;
  style?: CSSProperties;
  onClick?: () => void;
};

export function Button(props: Button) {
  const { label, style, submitted, disabled, fill = "solid", ...rest } = props;
  // Ionic button does not support border color change on hover
  // so instead we can use a react useState to change the border color
  const [isHover, setIsHover] = useState(false);

  const Styles = {
    outline: {
      "--color-hover": Colors.blue600,
      "--color-hover-opacity": "0.0",
      "--border-color": isHover ? Colors.blue600 : Colors.blue500,
      "--background-hover-opacity": "0.0",
    },
    solid: {
      "--background-hover": Colors.black,
      "--background-hover-opacity": "0.3",
    },
    disabled: {
      "--background": Colors.slate200,
      pointerEvents: "none",
      color: Colors.slate400,
      "--box-shadow": "0 2px 2px 0 #000 14.77",
      "--ripple-color": Colors.slate200,
    },
    clear: {},
  };

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  function getStyle(fill: buttonFill) {
    const buttonStyle = disabled ? Styles["disabled"] : Styles[fill];
    return {
      ...{
        minWidth: "110px",
        opacity: "0",
      },
      ...buttonStyle,
      ...style,
    };
  }

  const buttonRef = useRef<HTMLIonButtonElement>(null);

  useEffect(() => {
    if (buttonRef.current) {
      const animation = createAnimation()
        .addElement(buttonRef.current)
        /* cosider making duration conditional:
         * .duration(label === "Return Home" ? 500: 0)
         */
        .duration(500)
        .iterations(1)
        .afterStyles({ opacity: "1" })
        .keyframes([
          { offset: 0, opacity: "0" },
          { offset: 0.2, opacity: "0.1" },
          { offset: 0.3, opacity: "0.2" },
          { offset: 0.5, opacity: "0.3" },
          { offset: 0.7, opacity: "0.5" },
          { offset: 1, opacity: "1" },
        ]);
      animation.play();
    }
  }, [label]);

  const ionFill = disabled ? "solid" : fill;

  return (
    <IonButton
      fill={ionFill}
      ref={buttonRef}
      style={getStyle(ionFill)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...rest}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 10px 0 10px",
        }}
      >
        <IonSpinner
          name="circular"
          style={{
            margin: submitted ? "-5px 10px -5px -10px" : "-12px",
            visibility: submitted ? "visible" : "hidden",
            /* display: submitted ? "block" : "none", */
            opacity: submitted ? "0.5" : "0",
            transition: "visibility 0.5s ease-in, opacity 0.5s ease-in",
            width: "24px",
            height: "24px",
          }}
        />
        {label}
      </div>
    </IonButton>
  );
}
