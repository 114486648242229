import { EngagementMedium, init as initSaaSquatch, ready, widgets } from "@saasquatch/squatch-js";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useRef } from "react";

import { AlertBoxError, ContentSingleColumn } from "~components";
import { GET, msg, ReferralData, SAASQUATCH_TENANT_ALIAS } from "~utils";
import { ScorePage } from "../../ScoreTemplates";

export function Referral() {
  const { data, error, isLoading } = useQuery<{ data: ReferralData }, AxiosError>({
    queryKey: ["fetchReferral"],
    queryFn: () => GET("/api/sub/referral"),
  });

  // Track if initialized to prevent re-initialization on re-render
  const isSaaSquatchInitialized = useRef(false);

  useEffect(() => {
    if (!isSaaSquatchInitialized.current) {
      initSaaSquatch({ tenantAlias: SAASQUATCH_TENANT_ALIAS });
      isSaaSquatchInitialized.current = true;
    }
  }, []);

  useEffect(() => {
    if (!data?.data) return;

    const widgetConfig = {
      user: data.data,
      widgetType: msg("MORE_REFERRAL_PROGRAM_WIDGET_TYPE"),
      engagementMedium: "EMBED" as EngagementMedium,
      container: "#squatchembed",
    };

    ready(() => {
      widgets()
        ?.upsertUser(widgetConfig)
        .then((response) => response.widget.open());
    });
  }, [data]);

  return (
    <ScorePage title={msg("MORE_REFERRAL_PROGRAM")} hasBack isLoading={isLoading}>
      <ContentSingleColumn>
        {error ? (
          <AlertBoxError />
        ) : (
          <div id="squatchembed" style={{ width: "100%", padding: "24px 16px" }}></div>
        )}
      </ContentSingleColumn>
    </ScorePage>
  );
}
