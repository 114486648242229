// import { formatAsMonthAndDay } from "~utils";

import { TaskCardBase, TaskCardBaseProps } from "~components";
import { TaskComputed } from "~skillBuilder/utils";

type TaskCardProps = Omit<TaskComputed, "steps"> & {
  /**
   * Fn to handle click events
   */
  onClick?: () => void;
  /**
   * Boolean to determine whether Task is locked currently
   */
  disabled?: boolean;
};

function taskCardBaseProps(props: TaskCardProps): TaskCardBaseProps {
  const { icon, durationTimeText, name, progress, status, disabled, onClick } = props;

  const progressPercent = progress * 100;
  const baseProps = {
    title: name,
    description: durationTimeText,
    onClick: onClick,
  };

  if (disabled) {
    return {
      ...baseProps,
      progressIcon: {
        icon: "Lock",
        color: "SLATE",
        percentage: progressPercent,
      },
    };
  }

  if (status === "COMPLETED") {
    return {
      ...baseProps,
      boxShadow: true,
      progressIcon: {
        icon: "Check Circle",
        color: "GREEN",
        percentage: progressPercent,
      },
    };
  }

  return {
    ...baseProps,
    boxShadow: true,
    progressIcon: {
      icon: icon,
      color: "BLUE",
      percentage: progressPercent,
    },
  };
}

export function TaskCard(props: TaskCardProps) {
  return <TaskCardBase {...taskCardBaseProps(props)} />;
}
