import { UseMutateAsyncFunction, useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import { AlertBox } from "swing-components";

import {
  AlertBoxError,
  ButtonScoreWrapper,
  ContentSingleColumn,
  LocationProps,
  LocationSearch,
  LocationsList,
} from "~components";
import {
  GET,
  LocationSettingsData,
  LocationSettingsErrorResponse,
  LocationSettingsPayload,
  msg,
  POST,
  useIsDesktop,
} from "~utils";
import { ScorePage } from "../../ScoreTemplates/ScorePage";
import styles from "./LocationSettings.module.css";

// TODO: find a better way to avoid the refetchInterval
// from running when running tests (Storybook)
type LocationSettingsProps = {
  isTest?: boolean;
};

export function LocationSettings(props: LocationSettingsProps) {
  const { isTest } = props;
  const [userProvidedZipCode, setUserProvidedZipCode] = useState<string | undefined>(undefined);

  const {
    data,
    isLoading,
    error: queryError,
    refetch: queryRefetch,
  } = useQuery<{ data: LocationSettingsData }, AxiosError, { data: TransformedLocationData }>({
    queryKey: ["fetchLocationSettings", userProvidedZipCode],
    queryFn: () => GET("/api/sub/location", { queryParams: { zipCode: userProvidedZipCode } }),
    gcTime: 0, // immediately garbage collect initial "params === null" query
    refetchInterval: isTest ? false : 5 * 60 * 1000, // time in ms - minutes * seconds * milliseconds = 5 minutes
    select: (data) => ({ data: transformLocationSettingsData(data.data) }),
    refetchOnWindowFocus: (query) => query.state.status !== "error", // do not refetch when error is present
  });

  const {
    mutateAsync,
    error: mutationError,
    isPending: isLocationSavePending,
  } = useMutation<{ data: LocationSettingsData }, AxiosError, LocationSettingsPayload>({
    mutationFn: (payload) => {
      return POST("/api/sub/location", payload);
    },
    onSuccess: async () => {
      // choosing to force query to run vs. updating the cache due to
      // complex state management needed in the `view`
      await queryRefetch();
    },
  });

  return (
    <LocationSettingsView
      error={queryError || mutationError}
      isLoading={isLoading}
      locationData={data?.data}
      onZipCodeSubmit={setUserProvidedZipCode}
      onSave={mutateAsync}
      isLocationSavePending={isLocationSavePending}
    />
  );
}

type TransformedLocationTag = LocationSettingsData["locationTags"][number] & LocationProps;
type TransformedLocationData = Omit<LocationSettingsData, "locationTags"> & {
  locationTags: TransformedLocationTag[];
};

type LocationSettingsViewProps = {
  error?: AxiosError | null;
  isLoading?: boolean;
  locationData?: TransformedLocationData;
  onSave: UseMutateAsyncFunction<
    {
      data: LocationSettingsData;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosError<unknown, any>,
    LocationSettingsPayload,
    unknown
  >;
  onZipCodeSubmit: Dispatch<SetStateAction<string | undefined>>;
  isLocationSavePending?: boolean;
};

export function LocationSettingsView(props: LocationSettingsViewProps) {
  const { error, isLoading, locationData, onSave, onZipCodeSubmit, isLocationSavePending } = props;
  const isDesktop = useIsDesktop();

  // Store the ion-content element in a ref so we can avoid querying the DOM
  // multiple times
  const ionContentRef = useRef<HTMLIonContentElement | null>(null);

  // Handle alert box success message display
  const [showSaveSuccessMessage, setShowSaveSuccessMessage] = useState<boolean>(false);

  const [_locationTags, _setLocations] = useState<TransformedLocationTag[] | undefined>();
  const [_originalLocationTags, _setOriginalLocationTags] = useState<
    TransformedLocationTag[] | undefined
  >();
  // an error response will not contain the zip code so we
  // are keeping the in-flight zip code in memory to be able
  // to keep the input field populated when an error occurs
  const [_zipCode, _setZipCode] = useState<string>("");
  // track the original zip code to allow user to save zip
  // code even if the locations selected are the same
  const [_originalZipCode, _setOriginalZipCode] = useState<string>();

  // set locations within useEffect since initial data is undefined when component first renders
  useEffect(() => {
    // use this to house the user's currently saved zipCode
    // only store value from the initial query call to avoid
    // overriding with the in-flight zip code
    if (!_originalZipCode) {
      _setOriginalZipCode(locationData?.zipCode);
    }
    // use this to house the user's in-flight zipCode
    // only store value from the initial query call to
    // avoid overriding in-flight zip code with query response
    if (!_zipCode) {
      _setZipCode(locationData?.zipCode || "");
    }
    // use this to house the user's currently saved location tags
    _setOriginalLocationTags(locationData?.locationTags);
    // use this to house the users' in-flight location tags
    _setLocations(locationData?.locationTags);
  }, [_originalZipCode, _zipCode, locationData?.locationTags, locationData?.zipCode]);

  useEffect(() => {
    // Query the ion-content element to allow for scrolling to the top
    // when a user saves a location, ensuring the success message is visible.
    ionContentRef.current = document.getElementById("ion-content") as HTMLIonContentElement;
  }, []);

  // we need to disable the save button if no locations have been modified
  const [hasUserModifiedLocationSelections, setHasUserModifiedLocationSelections] =
    useState<boolean>(false);

  // keep track of selection counts so we can dynamically update the save button content
  const { totalLocationCount, totalSchoolCount } = useMemo(() => {
    const selectedLocations = _locationTags?.filter((lt) => lt.isSelected);
    const lCount = selectedLocations?.length;
    const sCount = selectedLocations?.reduce((acc, next) => (acc += next.schoolCount), 0);
    return { totalLocationCount: lCount, totalSchoolCount: sCount };
  }, [_locationTags]);

  const locationButtonLabel = isLocationSavePending
    ? msg("LOCATION_SETTINGS_SAVE_BUTTON_IN_PROGRESS")
    : `${msg("LABEL_SAVE")} ${totalLocationCount} ${totalLocationCount === 1 ? msg("LOCATION_SETTINGS_LOCATION") : msg("LOCATION_SETTINGS_LOCATIONS")}
  (${totalSchoolCount} ${totalSchoolCount === 1 ? msg("LOCATION_SETTINGS_SCHOOL") : msg("LOCATION_SETTINGS_SCHOOLS")})`;

  const isSaveButtonDisabled =
    // disabled when ...
    // - saved zip code is the same as in-flight zip code AND saved user locations are the same as in-flight locations
    // - selected in-flight locations are 0
    // - success message is being displayed
    (_originalZipCode === _zipCode && !hasUserModifiedLocationSelections) ||
    totalLocationCount === 0 ||
    showSaveSuccessMessage
      ? true
      : false;

  // error handling
  const errorType: "inline" | "generic" | "none" = useMemo(() => {
    if (error) {
      const errResponseData = error.response?.data as LocationSettingsErrorResponse;
      if (
        errResponseData &&
        (errResponseData.details.type === "invalid-zip" ||
          errResponseData.details.type === "out-of-state-zip")
      ) {
        return "inline";
      }
      return "generic";
    }
    return "none";
  }, [error]);

  function handleSearchSubmit(searchTerm: string) {
    _setZipCode(searchTerm);
    // trigger API query
    onZipCodeSubmit(searchTerm);
  }

  function handleCheckboxSelection(checkboxResponse: LocationProps[]) {
    // remove alert box success message display in case it is displayed
    if (showSaveSuccessMessage) {
      setShowSaveSuccessMessage(false);
    }

    // create mapper for more performant lookup
    const mapper = checkboxResponse.reduce(
      (acc, next) => {
        return {
          ...acc,
          [next.value]: {
            isSelected: next.isSelected,
            label: next.label,
            subText: next.subText,
            value: next.value,
          },
        };
      },
      {} as Record<string, LocationProps>,
    );

    // transform checkbox data to useState _locationTags data
    const locations = _locationTags?.map((locTag) => {
      const locationUiFields = mapper[locTag.value];
      return { ...locTag, ...locationUiFields };
    });

    // using `isSelected` to determine if in-flight data has been modified
    // compared to original data. Using `some` to exit once a difference is found
    const hasModifiedSelections = _originalLocationTags?.some((locTag) => {
      const locationUiFields = mapper[locTag.value];
      return locTag.isSelected !== locationUiFields.isSelected ? true : false;
    });

    setHasUserModifiedLocationSelections(!!hasModifiedSelections);
    _setLocations(locations);
  }

  // extract string ids of selected locations
  function handleSaveZipAndLocations() {
    const subLocationTagsSelected = _locationTags
      ?.filter((lts) => lts.isSelected)
      .map((lt) => lt.locationId);

    // trigger API mutation
    // a get request occurs before a zip code can be saved so we can
    // use the zipCode from the query and not the in-flight one
    onSave({
      zipCode: locationData?.zipCode || "",
      subLocationTags: subLocationTagsSelected || [],
    })
      .then(() => {
        // Scroll to the top so the success message is visible
        if (!isDesktop) {
          ionContentRef.current?.scrollToTop(500);
        }

        setShowSaveSuccessMessage(true);
        // update the user's actual saved zip code manually
        _setOriginalZipCode(locationData?.zipCode);
        // reset user modifications
        setHasUserModifiedLocationSelections(false);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.info("Saving zip and locations failed: ", err);
      });
  }

  return (
    <ScorePage title={msg("MORE_LOCATION_SETTINGS")} isLoading={isLoading} hasBack>
      <ContentSingleColumn>
        <div className={styles["location-settings-content-wrapper"]}>
          {errorType === "generic" && (
            <div className={styles["location-settings-message-wrapper"]}>
              <AlertBoxError margin="0px" />
            </div>
          )}
          {showSaveSuccessMessage && (
            <div className={styles["location-settings-message-wrapper"]}>
              <AlertBox
                color="success"
                title={msg("LOCATION_SETTINGS_SAVE_SUCCESS_TITLE")}
                showIcon
              >
                {msg("LOCATION_SETTINGS_SAVE_SUCCESS_MESSAGE")}.
              </AlertBox>
            </div>
          )}
          <div className={styles["location-settings-search-wrapper"]}>
            <LocationSearch
              hasError={errorType === "inline"}
              onChange={() => setShowSaveSuccessMessage(false)}
              onSearchSubmit={handleSearchSubmit}
              searchTerm={locationData?.zipCode || _zipCode}
            />
          </div>
          {errorType === "none" && (
            <>
              <div className={styles["location-settings-list-wrapper"]}>
                <LocationsList
                  locations={
                    _locationTags?.map(({ isSelected, label, subText, value }) => ({
                      isSelected,
                      label,
                      subText,
                      value,
                    })) || []
                  }
                  onChange={handleCheckboxSelection}
                />
              </div>
              <ButtonScoreWrapper
                buttonPrimary={{
                  label: locationButtonLabel,
                  expand: "block",
                  onClick: handleSaveZipAndLocations,
                  fill: "solid",
                  disabled: isSaveButtonDisabled,
                  isActionPending: isLocationSavePending,
                }}
                backgroundColor="white200"
              />
            </>
          )}
        </div>
      </ContentSingleColumn>
    </ScorePage>
  );
}

export function transformLocationSettingsData(data: LocationSettingsData) {
  return {
    ...data,
    locationTags: data.locationTags?.map((locTag) => {
      return {
        ...locTag,
        isSelected: data.subLocationTags.includes(locTag.locationId),
        label: locTag.locationName,
        subText: `${locTag.schoolCount} ${locTag.schoolCount === 1 ? msg("LOCATION_SETTINGS_SCHOOL") : msg("LOCATION_SETTINGS_SCHOOLS")}`,
        value: locTag.locationId,
      };
    }),
  } satisfies TransformedLocationData;
}
