import { Colors, Icon } from "swing-components";

import "swing-styles";

import { Button } from "~components";
import { StepComputed, UnsyncedSkillBuilderAttribute } from "~skillBuilder/utils";
import { Question } from "../Question";

type StepProps = {
  stepComputed: Omit<StepComputed, "prevButtonText" | "nextButtonText">;
  /**
   * When answering a Question, this function will pass the `value` to the
   * parent TaskPage.
   */
  onChange: ({ attribute, value }: UnsyncedSkillBuilderAttribute) => void;
  /**
   * Disabled the ability to answer any questions.
   * @default false
   */
  isReadOnly?: boolean;
  /**
   * Helps calculate the correct "Next" button label
   * @default false
   */
  isLastStep?: boolean;
  /**
   * Button props to for next button.
   */
  nextButtonProps: {
    label?: string;
    onClick: () => void;
    /**
     * Shows a loading spinner on the button
     * @default false
     */
    isLoading?: boolean;
    /**
     * @default false
     */
    disabled?: boolean;
  };
  /**
   * Button props to for prev button.
   */
  prevButtonProps?: {
    label?: string;
    onClick: () => void;
    /**
     * @default false
     */
    disabled?: boolean;
    // NOTE: This button cannot be in a loading state.
  };
};

export function Step(props: StepProps) {
  const {
    stepComputed,
    prevButtonProps,
    nextButtonProps,
    onChange,
    isReadOnly = false,
    isLastStep = false,
  } = props;
  const { status, questions, header } = stepComputed;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        gap: "40px",
      }}
    >
      {/* Header */}
      {header && (
        <header style={{ display: "flex", flexDirection: "column", gap: 16 }}>
          <h2
            style={{
              fontSize: 20,
              lineHeight: "30px",
              fontWeight: "600",
              margin: 0,
              color: Colors.black,
            }}
          >
            {header.title}
          </h2>

          {/* List of icon and label */}
          {header.decorations && (
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 16,
                paddingLeft: 24,
                margin: 0,
              }}
            >
              {header.decorations.map(({ icon, label }) => (
                <li key={`${header.title}-${label}`} style={{ display: "flex" }}>
                  <Icon name={icon} />
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "24px",
                      fontWeight: 400,
                      color: Colors.black,
                    }}
                  >
                    {label}
                  </span>
                </li>
              ))}
            </ul>
          )}
          {/* Divider */}
          <hr style={{ width: "100%", background: Colors.slate300, margin: 0 }} />
        </header>
      )}

      {/* Questions */}
      {questions.map((question) => (
        <Question
          key={question.attribute}
          {...question}
          value={question.value}
          onChange={onChange}
          isReadOnly={isReadOnly}
        />
      ))}

      {/* Buttons */}
      <div
        style={{
          display: "grid",
          // Rendering a single column when there is just the Next button
          gridTemplateColumns: `repeat(${!prevButtonProps ? 1 : 2}, 1fr)`,
        }}
      >
        {/* Previous Button */}
        {prevButtonProps && (
          <div>
            <Button
              onClick={prevButtonProps.onClick}
              // Either the given label or "Previous"
              label={prevButtonProps.label ?? "Previous"}
              disabled={prevButtonProps.disabled}
              fill="outline"
            />
          </div>
        )}

        {/* Next Button */}
        <div style={{ justifySelf: "flex-end" }}>
          <Button
            data-testid="step-next-button"
            onClick={nextButtonProps.onClick}
            label={getNextButtonLabel({
              label: nextButtonProps?.label,
              stepStatus: status,
              isLastStep,
              isReadOnly,
            })}
            disabled={nextButtonProps.disabled}
            submitted={nextButtonProps.isLoading ?? false}
            fill="solid"
          />
        </div>
      </div>
    </div>
  );
}

/********** Helper Functions *********/
/**
 * Returns the appropriate next button label following these rules:
 * 1. If on the last step and NOT in a read-only state, return either the given label or "Submit"
 *   since this can be customized in the Step configuration.
 * 2. When on the last step and IN a read-only state, return "Return Home" since the user cannot move
 * forward nor control this label.
 * 3. Otherwise, default to the given label or "Next"
 * */
function getNextButtonLabel(params: {
  label?: string;
  stepStatus: StepComputed["status"];
  isLastStep: boolean;
  isReadOnly: boolean;
}) {
  const { label, isLastStep, isReadOnly } = params;
  // When ON the last step and NOT IN a read-only state
  // Then return either the label or "Submit"
  if (isLastStep && !isReadOnly) return label ?? "Submit";
  // When ON the last step and IN a read-only state
  if (isLastStep && isReadOnly) return label ?? "Continue Learning";
  // Otherwise, default to the given label or "Next"
  return label ?? "Next";
}
